import { Box, Table } from '@mantine/core';

import classes from './TableCell.module.css';

interface TableCellProps<TColumn extends string> {
  width: number;
  isSticky?: boolean;
  column: TColumn;
  children: React.ReactNode | (() => React.ReactNode);
}

export function TableCell<TColumn extends string>({ width, isSticky, children }: TableCellProps<TColumn>) {
  const renderContent = () => {
    if (typeof children === 'function') {
      return children();
    }
    return children;
  };

  return (
    <Table.Td className={isSticky ? classes.stickyCell : undefined} w={width} p={0}>
      <Box px="md">{renderContent()}</Box>
    </Table.Td>
  );
}
