import { FC } from 'react';
import { Group, Stack, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useDebouncedCallback, useFocusWithin } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import { useUpdateUser } from '@features/users/api/updateUser';
import { User } from '@features/users';

interface UserInfoFormProps {
  initialUserData: User;
}

export const UserInfoForm: FC<UserInfoFormProps> = ({ initialUserData }) => {
  const { ref: refFirstName, focused: focusedFirstName } = useFocusWithin();
  const { ref: refLastName, focused: focusedLastName } = useFocusWithin();

  const { mutate: updateUser } = useUpdateUser({
    onSuccess: () => {
      notifications.show({
        title: 'Name updated',
        message: 'Your name has been updated successfully',
        color: 'green',
      });
      form.resetDirty();
      form.clearErrors();
    },
  });

  const debouncedUpdateUser = useDebouncedCallback(
    (values: { firstName: string; lastName: string }) => {
      if (!values.firstName.trim() || !values.lastName.trim()) {
        return;
      }
      updateUser({
        userId: initialUserData.id,
        firstName: values.firstName ? values.firstName.trim() : null,
        lastName: values.lastName ? values.lastName.trim() : null,
      });
    },
    500
  );

  const form = useForm({
    initialValues: {
      firstName: initialUserData.firstName,
      lastName: initialUserData.lastName,
    },
    validate: {
      firstName: (firstName) => (firstName.trim().length > 0 ? null : 'First name is required'),
      lastName: (lastName) => (lastName.trim().length > 0 ? null : 'Last name is required'),
    },
    onValuesChange: (values) => {
      form.validate();
      if (form.isValid()) {
        debouncedUpdateUser(values);
      }
    },
    validateInputOnChange: true,
  });

  return (
    <form>
      <Stack gap="md">
        <Group grow>
          <TextInput
            ref={refFirstName}
            label="First Name"
            variant={focusedFirstName ? 'default' : 'filled'}
            placeholder='e.g. John'
            type='text'
            {...form.getInputProps('firstName')}
          />
          <TextInput
            ref={refLastName}
            label="Last Name"
            variant={focusedLastName ? 'default' : 'filled'}
            placeholder='e.g. Doe'
            type='text'
            {...form.getInputProps('lastName')}
          />
        </Group>
        <TextInput
          label="Phone Number"
          description="Contact support to update your phone number"
          value={initialUserData.phoneNumber ?? '-'}
          readOnly
          disabled
          variant="filled"
        />
      </Stack>
    </form>
  );
};
