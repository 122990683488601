import { FC } from 'react';

import { Box, Button, Stack } from '@mantine/core';
import { Link } from 'react-router-dom';

import { useTryBlockitLink } from '@hooks/useTryBlockitLink';

export const OnboardingCompleteButtons: FC = () => {
  const mailToLink = useTryBlockitLink();

  if (!mailToLink) {
    return null;
  }

  return (
    <Stack gap="xs">
      <Box>
        <Button component="a" href={mailToLink} target="_blank" color="blue" size="sm">
          Start scheduling
        </Button>
      </Box>
      <Box>
        <Button component={Link} to="/" color="dimmed" size="xs" variant="subtle">
          Go to app
        </Button>
      </Box>
    </Stack>
  );
};
