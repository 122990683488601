import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';

import { axios } from '@lib/axios';

import { User } from '../types';

interface UpdateNameParams {
  userId: string;
  firstName: string | null;
  lastName: string | null;
}

const updateUser = async (params: UpdateNameParams) => {
  const { userId, firstName, lastName } = params;
  // TODO: We might want to change this endpoint to not pass userId since it's already scoped to the user via auth
  const { data } = await axios.patch(`/user/${userId}/patch`, {firstName, lastName});
  return data.user;
};

export const useUpdateUser = (options?: UseMutationOptions<User, Error, UpdateNameParams>) => {
  const queryClient = useQueryClient();
  const { onSuccess, ...otherOptions } = options ?? {};
  return useMutation({
    mutationFn: updateUser,
    onSuccess: (user, variables, context) => {
      queryClient.invalidateQueries({ queryKey: ['user'] });
      onSuccess?.(user, variables, context);
    },
    ...otherOptions
  });
}
