import { Group, Text, UnstyledButton } from '@mantine/core';
import { IconChevronDown, IconChevronUp, IconSelector } from '@tabler/icons-react';

export type SortDirection = 'asc' | 'desc' | null;

interface SortableHeaderProps<SColumnName extends string, TSortField extends SColumnName> {
  field: TSortField;
  activeField: TSortField | null;
  direction: SortDirection;
  onSort: (field: TSortField) => void;
  title: string;
}

export function SortableHeader<SColumnName extends string, TSortField extends SColumnName>({
  field,
  activeField,
  direction,
  onSort,
  title,
}: SortableHeaderProps<SColumnName, TSortField>) {
  return (
    <UnstyledButton onClick={() => onSort(field as TSortField)}>
      <Group gap={4} wrap="nowrap">
        <Text size="sm" fw={600}>
          {title}
        </Text>
        {field === activeField ? (
          direction === 'asc' ? (
            <IconChevronUp size={14} />
          ) : direction === 'desc' ? (
            <IconChevronDown size={14} />
          ) : (
            <IconSelector size={14} />
          )
        ) : (
          <IconSelector size={14} />
        )}
      </Group>
    </UnstyledButton>
  );
}
