import { useCalendarsByAccounts } from '@features/calendar-selection/api/getCalendars';
import { hasGoogleAccounts } from '@features/calendar-selection/utils/accountUtils';

export const useTryBlockitLink = () => {
  const { data: accounts } = useCalendarsByAccounts();

  if (!accounts) {
    return null;
  }

  const hasGoogle = hasGoogleAccounts(accounts);
  const cc = 'bot@blockit.com';
  const to = 'blockitdemo@gmail.com';
  const subject = 'Blockit Test';
  const body = 'Hi Harry, Would love to chat. Adding Blockit to share some options for next week.';

  const mailToLink = !hasGoogle
    ? `https://mail.google.com/mail/u/0/?fs=1&to=${to}&cc=${cc}&su=${subject}&body=${body}&tf=cm`
    : `mailto:${to}?cc=${cc}&subject=${subject}&body=${body}`;

  return mailToLink;
};
