import { useState } from 'react';

import { Button, Grid, Modal, Stack, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconPlus } from '@tabler/icons-react';

import { SideContent } from '@components/SideContent';

import { useContactsWithOrganizations } from '../api/getContacts';
import { SortField, SortState } from '../types';

import { ContactForm } from './ContactForm';
import { ContactFilters, ContactsFilters } from './ContactsFilters';
import { ContactsTable } from './ContactsTable';
import { SyncFromCalendar } from './SyncFromCalendar';

export const ContactsPage = () => {
  const [opened, { open, close }] = useDisclosure(false);
  const { data } = useContactsWithOrganizations();
  const [filters, setFilters] = useState<ContactFilters>({});
  const [sort, setSort] = useState<SortState>({ field: null, direction: null });

  const handleSort = (field: SortField) => {
    setSort((current) => ({
      field,
      direction:
        current.field === field
          ? current.direction === 'asc'
            ? 'desc'
            : current.direction === 'desc'
              ? null
              : 'asc'
          : 'asc',
    }));
  };

  if (!data) {
    return null;
  }

  return (
    <>
      <Stack gap="md" px="md">
        <SideContent
          sideContent={
            <Stack gap="md">
              <Text c="dimmed">This is your calendar graph, all in one place.</Text>
              <Grid gutter="sm">
                <Grid.Col span={{ base: 'content', xl: 12 }} order={{ base: 2, xl: 1 }}>
                  <Button leftSection={<IconPlus size={16} />} onClick={open} fullWidth>
                    Add Contact
                  </Button>
                </Grid.Col>
                <Grid.Col span={{ base: 'auto', xl: 12 }} order={{ base: 1, xl: 2 }}>
                  <ContactsFilters
                    contacts={data.contacts}
                    onFiltersChange={setFilters}
                    organizations={data.organizations}
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 'auto', xl: 12 }} order={{ base: 3, xl: 3 }}>
                  <SyncFromCalendar />
                </Grid.Col>
              </Grid>
            </Stack>
          }
          showFullWidth
        >
          <ContactsTable
            contacts={data.contacts}
            organizations={data.organizations}
            filters={filters}
            sort={sort}
            onSort={handleSort}
          />
        </SideContent>
      </Stack>

      <Modal opened={opened} onClose={close} title="Create New Contact" size="lg">
        <ContactForm organizations={data?.organizations ?? []} onSubmit={close} onCancel={close} isCreate />
      </Modal>
    </>
  );
};
