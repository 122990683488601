import { Button, Center, Grid, Group, Loader, ScrollArea, Stack, Text, Title } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import * as Sentry from '@sentry/react';
import { IconArrowLeft } from '@tabler/icons-react';
import { Link, Outlet, useParams } from 'react-router-dom';

import { NotFoundPage } from '@components/NotFoundPage';
import { TooltipActionIcon } from '@components/TooltipActionIcon';
import { PRIVATE_ROUTES } from '@routes/enums';

import { useCodewordTemplate } from '../../api/getCodewordTemplate';

import { Navigation } from './Navigation';

export const UpdatePageContainer = () => {
  const { id } = useParams();
  const { data: codewordTemplate, isLoading, error } = useCodewordTemplate(id);
  if (!id) {
    return <NotFoundPage />;
  }

  if (isLoading) {
    return <Loader size="xl" />;
  }

  if (error) {
    if (error.response && error.response.status === 404) {
      return (
        <Center>
          <Text size="xl">Oops! It looks like this codeword template does not exist.</Text>
        </Center>
      );
    }
    notifications.show({
      title: 'Error',
      message: 'An error occurred while fetching the codeword template.',
      color: 'red',
    });
    Sentry.captureException(error);
  }
  if (!codewordTemplate) {
    return null;
  }

  return (
    <Stack h="100%">
      <Group>
        <TooltipActionIcon
          component={Link}
          to={PRIVATE_ROUTES.TEMPLATES}
          variant="subtle"
          size="lg"
          aria-label="Back to list view"
          icon={<IconArrowLeft size={20} />}
        />
        <Title order={2} component="h1">
          Edit {codewordTemplate.name}
        </Title>
      </Group>
      <Grid h="100%" styles={{ inner: { height: '100%' } }} overflow="hidden">
        <Grid.Col span={{ xl: 'content', base: 12 }} h={{ base: 'auto', xl: '100%' }}>
          <Stack pos="relative" h="100%">
            <Navigation />
            <Button
              component={Link}
              to={PRIVATE_ROUTES.TEMPLATES}
              pos="sticky"
              bottom={10}
              mt="auto"
              style={{ alignSelf: 'flex-end' }}
            >
              Done
            </Button>
          </Stack>
        </Grid.Col>
        <Grid.Col span="auto" miw="300" h="100%">
          <ScrollArea type="always" h="100%">
            <Outlet />
          </ScrollArea>
        </Grid.Col>
      </Grid>
    </Stack>
  );
};
