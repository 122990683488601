import { FC } from 'react';

import { Card, Group, Select, Stack } from '@mantine/core';
import { notifications } from '@mantine/notifications';

import { TimeOfDay } from '@/types';
import { TimeInputSelect } from '@components/Form/TimeInputSelect';
import { InfoTooltip } from '@components/InfoTooltip';
import { Switch } from '@components/Switch';
import { MINUTES_IN_HOUR } from '@constants/index';
import { AnalyticsEventName, track } from '@features/analytics';
import { useCalendarsByAccounts } from '@features/calendar-selection';
import { usePreferences, useUpdatePreferences } from '@features/preferences';

import { useNotificationSettings, useUpsertNotificationSettings } from '../api';
import type { NotificationSettings } from '../types';

const DIGEST_MIN_START_HOUR = 12;
const DIGEST_MAX_END_HOUR = 24;

export const SettingsForm: FC = () => {
  const { data: settings } = useNotificationSettings();
  const { mutate: updateSettings } = useUpsertNotificationSettings();
  const { data: preferences } = usePreferences();
  const { mutate: updatePreferences, isPending: isUpdatingPreferences } = useUpdatePreferences({
    onSuccess: () => {
      notifications.show({
        title: 'Preferred email updated',
        message: 'Your preferred email has been updated',
        color: 'green',
      });
      track({
        type: AnalyticsEventName.NotificationSettingsUpdated,
        data: {
          setting: 'preferredEmail',
        },
      });
    },
  });
  const { data: accounts } = useCalendarsByAccounts();

  const handleToggle = (field: keyof NotificationSettings) => (checked: boolean) => {
    if (!settings) return;

    updateSettings({
      ...settings,
      [field]: checked,
    });
    track({
      type: AnalyticsEventName.NotificationSettingsUpdated,
      data: {
        setting: field,
      },
    });
  };

  const handleDigestHourChange = (value: TimeOfDay) => {
    if (!settings) return;

    const hour = parseInt(value.split(':')[0], 10);
    updateSettings({
      ...settings,
      hourToSendDigest: hour,
    });
    track({
      type: AnalyticsEventName.NotificationSettingsUpdated,
      data: {
        setting: 'hourToSendDigest',
      },
    });
  };

  if (!settings || !preferences || !accounts) return null;

  const handleDefaultAccountChange = (value: string | null) => {
    if (!value) {
      return;
    }

    updatePreferences({ ...preferences, defaultAccountId: value });
  };

  return (
    <Stack gap="md">
      <Select
        label="Preferred Email"
        description="Select the email address where you receive Blockit notifications"
        data={accounts.map((account) => account.email)}
        value={preferences.defaultAccountId}
        onChange={handleDefaultAccountChange}
        disabled={isUpdatingPreferences}
        allowDeselect={false}
        size="md"
        w={500}
      />
      <Card withBorder radius="md">
        <Stack gap="xs">
          <Switch
            checked={settings.digestEmailEnabled}
            onChange={(event) => handleToggle('digestEmailEnabled')(event.currentTarget.checked)}
            label="Daily Dossier Email"
            description="Get a daily email summary of tomorrow's meetings"
            size="md"
          />
          {settings.digestEmailEnabled && (
            <Stack pl="58" gap="xs">
              <TimeInputSelect
                label="Delivery Time"
                description="Choose around when you'd like to receive the email in your inbox"
                value={`${settings.hourToSendDigest.toString().padStart(2, '0')}:00` as TimeOfDay}
                onChange={handleDigestHourChange}
                size="sm"
                w={500}
                incrementInMinutes={MINUTES_IN_HOUR}
                rangeHours={{ start: DIGEST_MIN_START_HOUR, end: DIGEST_MAX_END_HOUR }}
              />

              <Switch
                checked={settings.privacyModeEnabled}
                onChange={(event) => handleToggle('privacyModeEnabled')(event.currentTarget.checked)}
                size="sm"
                label={
                  <Group gap="xs">
                    Privacy Mode
                    <InfoTooltip
                      description="Show events from non-preferred emails as 'private' in daily dossier, 
                        without any event details"
                    />
                  </Group>
                }
                description={
                  'Only show details for events on your preferred email calendar, ' +
                  "with events from other calendars shown as 'private'"
                }
              />
            </Stack>
          )}
        </Stack>
      </Card>

      <Card withBorder radius="md">
        <Switch
          checked={settings.tripsEmailEnabled}
          onChange={(event) => handleToggle('tripsEmailEnabled')(event.currentTarget.checked)}
          label={
            <Group gap="xs">
              Travel Detection Notifications
              <InfoTooltip
                description={
                  'Blockit searches for round-trip flights, all-day events with a location ' +
                  '(eg. hotel stay) or named "Trip to [location]" to detect travel.'
                }
              />
            </Group>
          }
          size="md"
          description="Blockit can proactively confirm upcoming travel with you when detected on your calendar"
        />
      </Card>
    </Stack>
  );
};
