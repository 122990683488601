import { FC } from 'react';

import { ActionIcon, Box, Button, Group, Text } from '@mantine/core';
import { IconArrowLeft, IconSettings, IconX } from '@tabler/icons-react';

import { getStepLabel, getStepProgress, TOTAL_STEPS } from '../constants';
import { OnboardingStep } from '../types';

interface MobileProgressBarProps {
  activeStep: OnboardingStep;
  prevStep: () => void;
  showUIPane: boolean;
  setShowUIPane: (show: boolean) => void;
}

export const MobileProgressBar: FC<MobileProgressBarProps> = ({ activeStep, prevStep, showUIPane, setShowUIPane }) => {
  return (
    <Box mb="md">
      <Group justify="space-between" align="center">
        <Group gap="xs">
          {activeStep > 0 && (
            <Button variant="subtle" size="compact-sm" leftSection={<IconArrowLeft size={16} />} onClick={prevStep}>
              Back
            </Button>
          )}
          <Text fw={500} size="sm">
            {getStepLabel(activeStep)}
          </Text>
        </Group>
        <Group gap="xs" align="center">
          <Text size="xs" c="dimmed">
            Step {activeStep + 1} of {TOTAL_STEPS}
          </Text>
          <ActionIcon
            variant="subtle"
            size="sm"
            onClick={() => setShowUIPane(!showUIPane)}
            aria-label={showUIPane ? 'Close settings' : 'Open settings'}
          >
            {showUIPane ? <IconX size={16} /> : <IconSettings size={16} />}
          </ActionIcon>
        </Group>
      </Group>
      <Box mt="xs" w="100%" h={4} bg="gray.2" style={{ borderRadius: 2 }}>
        <Box
          w={`${getStepProgress(activeStep)}%`}
          h={4}
          bg="blockitBlue"
          style={{
            borderRadius: 2,
            transition: 'width 0.3s ease',
          }}
        />
      </Box>
    </Box>
  );
};
