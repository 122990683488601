import { memo, forwardRef, useRef } from 'react';

import { Table } from '@mantine/core';
import { useInView } from 'framer-motion';

import { TableCell } from './TableCell';
import classes from './TableRow.module.css';

interface TableRowProps<TColumn extends string> {
  columnWidths: Record<TColumn, number>;
  renderCellContent: (column: TColumn) => React.ReactNode;
  height?: number;
  isInView?: boolean;
}

export const TableRow = memo(
  forwardRef(<TColumn extends string>({ columnWidths, renderCellContent, height = 46 }: TableRowProps<TColumn>) => {
    const ref = useRef<HTMLTableRowElement>(null);
    const isInView = useInView(ref, {
      margin: '0px 0px',
      amount: 0,
    });

    return (
      <Table.Tr ref={ref} h={height}>
        {Object.entries(columnWidths).map(([key, width], index) => (
          <TableCell key={key} width={width as number} isSticky={index === 0} column={key as TColumn}>
            {isInView ? (
              renderCellContent(key as TColumn)
            ) : (
              <div className={classes.placeholder} style={{ height: `${height}px` }} />
            )}
          </TableCell>
        ))}
      </Table.Tr>
    );
  }),
) as <TColumn extends string>(
  props: TableRowProps<TColumn> & { ref?: React.ForwardedRef<HTMLTableRowElement> },
) => JSX.Element;
