import { useQuery } from '@tanstack/react-query';

import { axios } from '@lib/axios';

export interface SlackTeamSummary {
  slackUserId: string;
  slackTeamId: string;
  slackTeamName: string;
  userId: string;
}

const getSlackTeams = async (): Promise<SlackTeamSummary[]> => {
  const response = await axios.get('/slack/teams');
  return response.data;
};

export const useSlackTeams = () => {
  return useQuery({
    queryKey: ['slackTeams'],
    queryFn: getSlackTeams,
  });
};
