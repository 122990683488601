import { FC } from 'react';

import { Box } from '@mantine/core';

import { SettingsForm } from '@features/preferences';
import { usePreferences } from '@features/preferences/api/getPreferences';

export const MeetingPreferencesStep: FC = () => {
  const { data: preferences } = usePreferences();

  if (!preferences) {
    return null;
  }

  return (
    <Box>
      <SettingsForm initialPreferences={preferences} />
    </Box>
  );
};
