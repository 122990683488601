import { FC } from 'react';

import { Button, Group } from '@mantine/core';

import classes from './SuggestedResponses.module.css';

interface SuggestedResponsesProps {
  responses: string[];
  onResponseClick: (response: string) => void;
  isDisabled: boolean;
}

export const SuggestedResponses: FC<SuggestedResponsesProps> = ({ responses, onResponseClick, isDisabled }) => {
  if (!responses || responses.length === 0) {
    return null;
  }

  return (
    <Group gap="xs" mb="md" align="flex-start">
      {responses.map((response, index) => (
        <Button
          key={index}
          variant="subtle"
          color="blue"
          size="sm"
          radius="md"
          ta="left"
          px="xs"
          fw={400}
          onClick={() => onResponseClick(response)}
          disabled={isDisabled}
          classNames={{
            root: classes.suggestionButton,
          }}
        >
          {response}
        </Button>
      ))}
    </Group>
  );
};
