import { FC } from 'react';

import { Box, Center, Divider, Stack, Text, Title } from '@mantine/core';

import { ProviderSignInButton } from '@components/ProviderSignInButton';
import { VerifiedText } from '@components/VerifiedText';
import { useFirebaseAuthState, useGoogleOAuthPopup } from '@features/auth';
import { useMicrosoftOAuthPopup } from '@features/auth/hooks/useMicrosoftOAuthPopup';
import { useCalendarsByAccounts } from '@features/calendar-selection';

export const ConnectCalendarStep: FC = () => {
  const { data: accounts } = useCalendarsByAccounts();
  const hasAccounts = !!accounts && accounts.length > 0;
  const authState = useFirebaseAuthState();
  const { openPopup, error } = useGoogleOAuthPopup();
  const { openPopup: openMicrosoftPopup, error: microsoftError } = useMicrosoftOAuthPopup();

  const handleGoogleLogin = () => {
    if (!authState.user) {
      throw new Error('User is not authenticated');
    }
    openPopup();
  };
  const handleMicrosoftLogin = () => {
    if (!authState.user) {
      throw new Error('User is not authenticated');
    }
    openMicrosoftPopup();
  };

  return (
    <Center>
      <Stack p="xl" maw="400px">
        <Title order={1} size="h2" ta="center" mb="md">
          {hasAccounts ? 'Connected Calendars' : 'Connect Your Calendar'}
        </Title>
        <Stack gap="xs" align="center">
          <ProviderSignInButton provider="google" onClick={handleGoogleLogin} />
          <ProviderSignInButton provider="microsoft" onClick={handleMicrosoftLogin} />
        </Stack>
        {accounts && accounts.length > 0 && <Divider my="md" />}
        <Center>
          <Stack gap="3">{accounts?.map((account) => <VerifiedText key={account.email} text={account.email} />)}</Stack>
        </Center>

        {(error || microsoftError) && (
          <Box mt="sm">
            {error && (
              <Text c="red" size="xs">
                {error.includes('Blockit requires calendar permission.')
                  ? 'Blockit requires calendar permission.' +
                    ' Make sure you check all permission boxes when connecting your calendar.'
                  : error ?? microsoftError}
              </Text>
            )}
          </Box>
        )}
      </Stack>
    </Center>
  );
};
