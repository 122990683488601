import { Stack, Text } from '@mantine/core';

import { SideContent } from '@components/SideContent';

import { PlacesContainer } from './PlacesContainer';

export const PlacesPage = () => {
  return (
    <SideContent
      sideContent={
        <Stack>
          <Text c="dimmed">Add your locations to ensure that we suggest places to meet near you.</Text>
        </Stack>
      }
    >
      <PlacesContainer />
    </SideContent>
  );
};
