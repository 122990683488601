export const INTERNAL_USER_IDS = new Set([
  'wfnLmMzkfDexO1rGTPCXIELsyl83', // kais
  'of7yvkx3UXSzKTPlStUCWYMTafn2', // john
  'luBGeIRMdPQNpUQ0QtYPJWKIsTd2', // ck
  'vaaIncqYcgXqDLMGV7iQmN9gyWm1', // lindsey
  'PqpwBMLj7qNX0rIhQnVO3tKKZ6D3', // john-test
  'tEMNRuzACfUSmuWkV0TCDsEILko2', // staging
  'nRG6ewD8SyPsy08Xot07AKoNevf1', // 650-555-1234
  'wfnLmMzkfDexO1rGTPCXIELsyl83', // kais
  'ezsivcRFf0QGXHi4oNufFZlX7V02', // pruthvi
  'uBrFGsB6WoNaGdy4Xe2tyOkpLMh1', // christina
  'Wwhx709v7OTnYJnxUFIz5uD3Co12', // delaney
  '8FuGXCBNSpUlFyPJuYZVus0FkY33', // daniel
]);
