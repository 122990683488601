import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import type { DateSpanApi, EventInput } from '@fullcalendar/core';
import interactionPlugin from '@fullcalendar/interaction';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import { Box, Group, ScrollArea, Text, alpha, useMantineTheme } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import { IconStar } from '@tabler/icons-react';
import { v4 as uuidv4 } from 'uuid';

import { DayOfWeek } from '@/types';
import { InfoTooltip } from '@components/InfoTooltip';

import { Availability, AvailabilityBlockType, PreferenceSettings } from '../types';
import { mergeEvents } from '../utils/eventMerging';
import {
  AvaibilityEvent,
  EventMeetingType,
  areAvailabilitiesEqual,
  convertAvailabilityToEvents,
  convertEventsToAvailability,
  convertWorkingHoursToBusinessHours,
  eventMetadataFromType,
  isEventOnOneDay,
  isEventOutsideWorkingHours,
} from '../utils/fullCalendar';
import { validateEvent } from '../utils/validateAvailability';

import { AvailabilityBlock } from './AvailabilityBlock';

// In most cases, we want to use css modules for css to avoid css leaking
// in unexpected places, however we have to use regular css here to override
// classes in the fullcalendar library
import './AvailabilityCalendar.css';

interface AvailabilityCalendarProps {
  initialAvailability: Availability;
  updateAvailability: (availability: Availability, callback?: () => void) => void;
  isUpdating: boolean;
  preferences: PreferenceSettings;
}

interface EventContentProps {
  timeText: string;
  event: AvaibilityEvent;
}

export const AvailabilityCalendar: FC<AvailabilityCalendarProps> = ({
  initialAvailability,
  updateAvailability,
  isUpdating,
  preferences,
}) => {
  const [events, setEvents] = useState<AvaibilityEvent[] | null>(null);
  const [eventType] = useState<AvailabilityBlockType>(AvailabilityBlockType.AVAILABLE);
  const theme = useMantineTheme();
  const workingHours = preferences.workingHours;
  const availability = initialAvailability;
  const calendarRef = useRef<FullCalendar | null>(null);

  const [isGrabbing, { close: stopGrabbing, open: startGrabbing }] = useDisclosure();
  const grabbingClass = isGrabbing ? 'grabbing-cursor' : '';

  const [isResizing, { close: stopResizing, open: startResizing }] = useDisclosure();
  const resizingClass = isResizing ? 'row-resize-cursor' : '';

  const eventColorsFromType = useCallback(
    (type: AvailabilityBlockType) => {
      const baseColor = type === AvailabilityBlockType.AVAILABLE ? theme.colors.blue[6] : theme.colors.yellow[6];
      return {
        textColor: baseColor,
        backgroundColor: alpha(baseColor, 0.1),
        borderColor: baseColor,
      };
    },
    [theme],
  );

  const defaultEventDetails = useMemo(() => {
    const { title, extendedProps } = eventMetadataFromType(eventType);

    return {
      title,
      extendedProps,
      overlap: false,
    };
  }, [eventType]);

  useEffect(() => {
    setEvents(
      convertAvailabilityToEvents(availability).map((event) => ({
        ...defaultEventDetails,
        ...eventColorsFromType(event.extendedProps?.type),
        ...event,
      })),
    );
  }, [eventColorsFromType, availability, defaultEventDetails]);

  // Callback used to tell the calendar to change its size when the container resizes
  const resizeObserverCallback = useCallback((node: HTMLElement | null) => {
    if (!node) return;
    const resizeObserver = new ResizeObserver(() => {
      const calendar = calendarRef.current;
      if (!calendar) return;
      calendar.getApi().updateSize();
    });

    // Observe the div
    if (node) {
      resizeObserver.observe(node);
    }

    // Cleanup function
    return () => {
      if (node) {
        resizeObserver.unobserve(node);
      }
    };
  }, []);

  const handleSlotSelect = (info: { start: Date; end: Date }) => {
    if (!calendarRef.current) return;
    calendarRef.current.getApi().unselect();

    if (!events || !preferences) return;

    const newEvent = {
      ...defaultEventDetails,
      ...eventColorsFromType(eventType),
      start: info.start,
      end: info.end,
      id: uuidv4(),
      extendedProps: {
        ...defaultEventDetails.extendedProps,
        meetingType: EventMeetingType.Any,
      },
    };

    const validationError = validateEvent(newEvent, events, workingHours || undefined);
    if (validationError !== null) {
      notifications.show(validationError);
      return;
    }

    const updatedEvents = mergeEvents(events, newEvent);
    updateAvailability(convertEventsToAvailability(updatedEvents));
  };

  const handleEventDelete = (event: EventInput) => {
    event.remove();
  };

  const renderEventContent = (eventInfo: EventContentProps) => {
    return (
      <AvailabilityBlock
        {...eventInfo}
        onDelete={handleEventDelete}
        eventColorsFromType={eventColorsFromType}
        eventType={eventType}
        events={events || []}
        updateAvailability={updateAvailability}
        preferences={preferences}
      />
    );
  };

  const handleAllowEvent = (span: DateSpanApi) => {
    if (!calendarRef.current || !workingHours) return false;
    const newEvent = { start: span.start, end: span.end };
    if (!isEventOnOneDay(newEvent)) {
      return false;
    }
    if (isEventOutsideWorkingHours(newEvent, workingHours)) {
      return false;
    }

    return true;
  };

  if (!availability || !workingHours || !events) {
    return null;
  }

  const showWeekends = Boolean(workingHours[DayOfWeek.Saturday]?.length || workingHours[DayOfWeek.Sunday]?.length);

  return (
    <Box>
      <Group gap="xs" justify="space-between" maw="1200">
        <Group gap="xs">
          <IconStar size="1rem" />
          <Group gap="1">
            <Text>Drag preferred meeting hours on this calendar.</Text>
            <InfoTooltip description="Blockit will prioritize suggesting meeting times during preferred hours." />
          </Group>
        </Group>
      </Group>
      <ScrollArea w="100%" maw="1200" h="100%" pb="xs" ref={resizeObserverCallback}>
        <Box miw={showWeekends ? 1100 : 800} className={`${grabbingClass} ${resizingClass}`}>
          <FullCalendar
            ref={calendarRef}
            longPressDelay={500}
            plugins={[timeGridPlugin, interactionPlugin]}
            initialView="timeGridWeek"
            headerToolbar={{ left: '', center: '', right: '' }}
            weekends={showWeekends}
            events={events}
            eventContent={renderEventContent}
            dayHeaderFormat={{ weekday: 'long' }}
            allDaySlot={false}
            slotDuration={'00:30:00'}
            slotLabelInterval={'01:00:00'}
            firstDay={1}
            slotLabelFormat={{ hour: 'numeric', meridiem: 'short' }}
            select={handleSlotSelect}
            selectable={!isUpdating}
            editable={!isUpdating}
            eventOverlap={false}
            scrollTime={'08:00:00'}
            eventMinHeight={1}
            dragScroll={true}
            eventAllow={handleAllowEvent}
            eventTextColor={eventColorsFromType(eventType).textColor}
            eventColor={eventColorsFromType(eventType).backgroundColor}
            selectMirror={true}
            eventTimeFormat={{ hour: 'numeric', minute: '2-digit' }}
            eventResizableFromStart={true}
            eventDragStart={() => startGrabbing()}
            eventDragStop={() => stopGrabbing()}
            eventResizeStart={() => startResizing()}
            eventResizeStop={() => stopResizing()}
            eventsSet={(events) =>
              !areAvailabilitiesEqual(convertEventsToAvailability(events), availability) &&
              updateAvailability(convertEventsToAvailability(events))
            }
            businessHours={convertWorkingHoursToBusinessHours(workingHours)}
            dayHeaderContent={(arg) => {
              return (
                <Box my="md">
                  <Text>{arg.text}</Text>
                </Box>
              );
            }}
          />
        </Box>
      </ScrollArea>
    </Box>
  );
};
