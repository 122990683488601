import { Box, Table, Text } from '@mantine/core';
import { mergeRefs, useEventListener } from '@mantine/hooks';

import { SortDirection, SortableHeader } from './SortableHeader';
import classes from './TableHeaderColumn.module.css';

interface TableHeaderColumnProps<SColumnName extends string, TSortField extends SColumnName> {
  column: {
    key: SColumnName;
    width: number;
    title: string;
    sortable?: boolean;
  };
  sort?: {
    field: TSortField | null;
    direction: SortDirection;
  };
  onSort?: (field: TSortField) => void;
  onResize: (column: SColumnName, width: number) => void;
  isSticky?: boolean;
}

// TSortField is the set of sortable column keys
// SColumnName is the set of all column keys

export function TableHeaderColumn<SColumnName extends string, TSortField extends SColumnName>({
  column,
  sort,
  onSort,
  onResize,
  isSticky,
}: TableHeaderColumnProps<SColumnName, TSortField>) {
  const headerClass = isSticky ? classes.stickyHeader : classes.header;

  const handleResize = (event: MouseEvent | TouchEvent) => {
    const startX = 'touches' in event ? event.touches[0].clientX : event.clientX;
    const startWidth = column.width;

    const handleMove = (e: MouseEvent | TouchEvent) => {
      const currentX = 'touches' in e ? e.touches[0].clientX : e.clientX;
      const diff = currentX - startX;
      onResize(column.key, Math.max(100, startWidth + diff));
    };

    const handleEnd = () => {
      document.removeEventListener('mousemove', handleMove);
      document.removeEventListener('mouseup', handleEnd);
      document.removeEventListener('touchmove', handleMove);
      document.removeEventListener('touchend', handleEnd);
    };

    document.addEventListener('mousemove', handleMove);
    document.addEventListener('mouseup', handleEnd);
    document.addEventListener('touchmove', handleMove);
    document.addEventListener('touchend', handleEnd);
  };

  const resizeRef = useEventListener('mousedown', handleResize);
  const touchRef = useEventListener('touchstart', handleResize);

  return (
    <Table.Th className={headerClass} w={column.width} p={0}>
      <Box className={classes.headerContent}>
        {column.sortable && sort && onSort ? (
          <SortableHeader
            field={column.key as TSortField}
            activeField={sort.field as TSortField | null}
            direction={sort.direction}
            onSort={onSort}
            title={column.title}
          />
        ) : (
          <Text size="sm" fw={500}>
            {column.title}
          </Text>
        )}
      </Box>
      <Box ref={mergeRefs(resizeRef, touchRef)} className={classes.resize} />
    </Table.Th>
  );
}
