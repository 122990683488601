import { OnboardingFieldNames, OnboardingRoutes, OnboardingSectionName, OnboardingStep } from './types';

export const ONBOARDING_FIELD_NAMES_TO_STEP: Record<OnboardingFieldNames, OnboardingStep> = {
  [OnboardingFieldNames.MEETING_HOURS]: OnboardingStep.Availability,
  [OnboardingFieldNames.PREFERRED_HOURS]: OnboardingStep.Availability,
  [OnboardingFieldNames.OFFICE_LOCATION]: OnboardingStep.Places,
  [OnboardingFieldNames.HOME_LOCATION]: OnboardingStep.Places,
  [OnboardingFieldNames.WORK_FROM_HOME_DAYS]: OnboardingStep.Places,
  [OnboardingFieldNames.COFFEE_LOCATION]: OnboardingStep.Places,
  [OnboardingFieldNames.UPCOMING_TRIPS]: OnboardingStep.Places,
  [OnboardingFieldNames.WORK_FROM_OFFICE_DAYS]: OnboardingStep.Places,
  [OnboardingFieldNames.REMOTE_MEETING_PLATFORM]: OnboardingStep.Video,
  [OnboardingFieldNames.CUSTOM_REMOTE_MEETING_LINK]: OnboardingStep.Video,
  [OnboardingFieldNames.CONNECT_ZOOM]: OnboardingStep.Video,
  [OnboardingFieldNames.MEETING_LIMITS]: OnboardingStep.MeetingPreferences,
  [OnboardingFieldNames.MEETING_BREAKS]: OnboardingStep.MeetingPreferences,
  [OnboardingFieldNames.MEETING_DURATIONS]: OnboardingStep.MeetingPreferences,
  [OnboardingFieldNames.TRAVEL_TIME]: OnboardingStep.MeetingPreferences,
  [OnboardingFieldNames.FLEXIBILITY]: OnboardingStep.MeetingPreferences,
};

export const ONBOARDING_SECTION_NAME_TO_STEP: Record<OnboardingSectionName, OnboardingStep> = {
  [OnboardingSectionName.AVAILABILITY]: OnboardingStep.Availability,
  [OnboardingSectionName.PLACES]: OnboardingStep.Places,
  [OnboardingSectionName.VIDEO]: OnboardingStep.Video,
  [OnboardingSectionName.MEETING_PREFERENCES]: OnboardingStep.MeetingPreferences,
  [OnboardingSectionName.BUFFERS]: OnboardingStep.MeetingPreferences,
};

export const ONBOARDING_STEP_TO_ROUTE: Record<OnboardingStep, string> = {
  [OnboardingStep.Calendars]: OnboardingRoutes.CALENDARS,
  [OnboardingStep.Availability]: OnboardingRoutes.AVAILABILITY,
  [OnboardingStep.Places]: OnboardingRoutes.PLACES,
  [OnboardingStep.Video]: OnboardingRoutes.VIDEO,
  [OnboardingStep.MeetingPreferences]: OnboardingRoutes.MEETING_PREFERENCES,
};

export const LOCATION_AUTOCOMPLETE_QUESTION_FIELD_NAMES = [
  OnboardingFieldNames.OFFICE_LOCATION,
  OnboardingFieldNames.HOME_LOCATION,
  OnboardingFieldNames.COFFEE_LOCATION,
];

export const STEP_LABELS = ['Calendars', 'Availability', 'Places', 'Video'];

export const STEP_DESCRIPTIONS = [
  'Connect calendars to Blockit',
  'Configure working hours and preferred times',
  'Add locations and travel',
  'Connect Zoom account',
];

export const TOTAL_STEPS = STEP_LABELS.length;

export const getStepLabel = (step: OnboardingStep): string => {
  return STEP_LABELS[step];
};

export const getStepProgress = (step: OnboardingStep): number => {
  return ((step + 1) / TOTAL_STEPS) * 100;
};

export const STEP_CHAT_PLACEHOLDER_TEXT: Record<OnboardingStep, string> = {
  [OnboardingStep.Calendars]: 'Ask Blockit anything',
  [OnboardingStep.Availability]: 'Share your ideal meeting times',
  [OnboardingStep.Places]: 'Share your points of interest',
  [OnboardingStep.Video]: 'Share your video preferences',
  [OnboardingStep.MeetingPreferences]: 'Share your meeting preferences',
};
