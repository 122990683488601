import { Divider, Stack, Title } from '@mantine/core';

import { PreferenceSettings } from '../types';
import { DefaultSchedulingWindow } from './DefaultSchedulingWindow';
import { DefaultTimeZone } from './DefaultTimeZone';
import { MaxMeetingMinutes } from './MaxMeetingMinutes';
import { MeetingBuffers } from './MeetingBuffers';
import { MeetingNudge } from './MeetingNudge';

interface GeneralPreferencesProps {
  initialPreferences: PreferenceSettings;
}

export const GeneralPreferences = ({ initialPreferences }: GeneralPreferencesProps) => {
  return (
    <Stack gap="xl">
      <Title order={2}>
            General Meeting Preferences
        </Title>
      <MeetingNudge initialPreferences={initialPreferences} />
      <Divider />
      <MeetingBuffers initialPreferences={initialPreferences} />
      <Divider />
      <MaxMeetingMinutes initialPreferences={initialPreferences} />
      <Divider />
      <DefaultSchedulingWindow initialPreferences={initialPreferences} />
      <Divider />
      <DefaultTimeZone initialPreferences={initialPreferences} />
    </Stack>
  );
}; 