import { FC } from 'react';

import { Stack, Text, Button } from '@mantine/core';

const STRIPE_PORTAL_LOGIN_URL = 'https://billing.stripe.com/p/login/aEU6qQ15ce189J6000';

export const BillingDetails: FC = () => (
  <Stack>
    <Text c="dimmed">
      Access your complete billing history, manage payment methods, and download invoices through our billing portal.
    </Text>
    <Button component="a" href={STRIPE_PORTAL_LOGIN_URL} target="_blank" rel="noopener noreferrer" w="fit-content">
      Go to portal
    </Button>
  </Stack>
);
