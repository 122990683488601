import { FC, useRef, useState } from 'react';

import type { EventInput } from '@fullcalendar/core';
import { ActionIcon, Group, Popover, Select, Stack, Text } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconLocation, IconStar, IconVideo, IconX } from '@tabler/icons-react';

import { DayOfWeek } from '@/types';

import { Availability, AvailabilityBlockType, PreferenceSettings } from '../types';
import { copyAvailabilityEventToDays } from '../utils/copyAvailabilityEventAcrossDays';
import { mergeEvents } from '../utils/eventMerging';
import {
  AvaibilityEvent,
  EventMeetingType,
  convertEventsToAvailability,
  eventMetadataFromType,
  convertDateInputToDateTime,
} from '../utils/fullCalendar';
import { validateEvent } from '../utils/validateAvailability';

import { CopyPreferenceHoursDropdown } from './CopyPreferenceHoursDropdown';

interface AvailabilityBlockProps {
  timeText: string;
  event: AvaibilityEvent;
  onDelete: (event: EventInput) => void;
  eventColorsFromType: (type: AvailabilityBlockType) => {
    textColor: string;
    backgroundColor: string;
    borderColor: string;
  };
  eventType: AvailabilityBlockType;
  events?: AvaibilityEvent[];
  updateAvailability: (availability: Availability, callback: () => void) => void;
  preferences?: PreferenceSettings;
}

export const AvailabilityBlock: FC<AvailabilityBlockProps> = ({
  timeText,
  event,
  onDelete,
  eventColorsFromType,
  eventType,
  events,
  updateAvailability,
  preferences,
}) => {
  const [opened, setOpened] = useState(false);
  const closeTimeout = useRef<NodeJS.Timeout>();

  const textColor = event.extendedProps?.type
    ? eventColorsFromType(event.extendedProps.type).textColor
    : eventColorsFromType(eventType).textColor;

  const handleMeetingTypeChange = (value: string | null) => {
    const newEvent: AvaibilityEvent = {
      start: event.start,
      end: event.end,
      id: event.id,
      textColor: event.textColor,
      backgroundColor: event.backgroundColor,
      borderColor: event.borderColor,
      extendedProps: {
        ...event.extendedProps,
        meetingType: value as EventMeetingType,
      },
    };

    // Update availability if the events changed
    if (events) {
      const updatedEvents = events.map((e) => (e.id === event.id ? newEvent : e));
      updateAvailability(convertEventsToAvailability(updatedEvents), () => setOpened(false));
    }
  };

  const getMeetingTypeIcon = (type: EventMeetingType | null) => {
    switch (type) {
      case EventMeetingType.InPerson:
        return <IconLocation size={16} />;
      case EventMeetingType.Remote:
        return <IconVideo size={16} />;
      case EventMeetingType.Any:
      default:
        return <IconStar size={16} />;
    }
  };

  const handleMouseLeave = () => {
    closeTimeout.current = setTimeout(() => {
      setOpened(false);
    }, 300); // 300ms delay before closing
  };

  const handleMouseEnter = () => {
    if (closeTimeout.current) {
      clearTimeout(closeTimeout.current);
    }
    setOpened(true);
  };

  const handleCopyAvailability = (daysToCopy: DayOfWeek[]) => {
    if (!events || !event.start) return;

    const currentDay = convertDateInputToDateTime(event.start).weekday.toString() as DayOfWeek;
    const workingDays = Object.entries(preferences?.workingHours || {})
      .filter(([_, hours]) => hours && hours.length > 0)
      .map(([day]) => day as DayOfWeek);

    // Filter out the current day from daysToCopy
    const validDaysToCopy = daysToCopy.filter((day) => workingDays.includes(day) && day !== currentDay);
    if (validDaysToCopy.length === 0) {
      return;
    }

    // Create all new events
    const newEvents = copyAvailabilityEventToDays({
      sourceEvent: event,
      targetDays: validDaysToCopy,
    });

    if (newEvents.length === 0) {
      return;
    }

    // Process each event independently
    let updatedEvents = [...events];
    const validationErrors = new Set<string>();
    let hasSkippedEvents = false;

    newEvents.forEach((newEvent) => {
      const validationError = validateEvent(newEvent, updatedEvents, preferences?.workingHours || undefined);
      if (validationError) {
        hasSkippedEvents = true;
        validationErrors.add(validationError.message);
        return; // Skip this event but continue with others
      }

      // If validation passes, merge the event
      updatedEvents = mergeEvents(updatedEvents, newEvent);
    });

    // If we skipped any events, show a notification
    if (hasSkippedEvents) {
      notifications.show({
        title: 'Some events could not be copied',
        message: Array.from(validationErrors).join('\n'),
        color: 'yellow',
      });
    }

    // Update availability with all valid events
    updateAvailability(convertEventsToAvailability(updatedEvents), () => {});
  };

  return (
    <>
      <Stack gap="1" h="100%" justify="space-between">
        <Stack gap="1">
          <Group justify="space-between" w="100%">
            <Group gap="2" align="center">
              <Popover
                opened={opened}
                onChange={setOpened}
                position="bottom-start"
                width={200}
                withArrow
                closeOnClickOutside={false}
              >
                <Popover.Target>
                  <ActionIcon
                    size="sm"
                    variant="subtle"
                    color={textColor}
                    onClick={() => setOpened((o) => !o)}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    {getMeetingTypeIcon(event.extendedProps.meetingType)}
                  </ActionIcon>
                </Popover.Target>

                <Popover.Dropdown p="xs" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                  <Stack gap="xs">
                    <Text size="xs" c="dimmed">
                      Choose your preferred meeting format for this block.
                    </Text>
                    <Select
                      size="xs"
                      value={event.extendedProps.meetingType}
                      onChange={(value) => {
                        handleMeetingTypeChange(value);
                      }}
                      data={[
                        {
                          value: EventMeetingType.Any,
                          label: 'Any',
                        },
                        {
                          value: EventMeetingType.InPerson,
                          label: 'In-person',
                        },
                        {
                          value: EventMeetingType.Remote,
                          label: 'Remote',
                        },
                      ]}
                      checkIconPosition="right"
                      allowDeselect={false}
                    />
                  </Stack>
                </Popover.Dropdown>
              </Popover>
              <Text fw={700} size="xs">
                {event.title || eventMetadataFromType(eventType).title}
              </Text>
            </Group>
            <ActionIcon size="sm" onClick={() => onDelete(event)} variant="transparent" color={textColor}>
              <IconX size={12} />
            </ActionIcon>
          </Group>
          <Text size="xs">{timeText}</Text>
        </Stack>
        <Group justify="flex-end" pr={3} pb={3}>
          <CopyPreferenceHoursDropdown
            currentDayIndex={
              event.start ? (convertDateInputToDateTime(event.start).weekday.toString() as DayOfWeek) : '1'
            }
            onSubmit={handleCopyAvailability}
            workingHours={preferences?.workingHours || undefined}
          />
        </Group>
      </Stack>
    </>
  );
};
