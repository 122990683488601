import { FC } from 'react';

import { Group, Text } from '@mantine/core';
import { IconCircleCheck } from '@tabler/icons-react';

interface VerifiedTextProps {
  text?: string;
}

/**
 * Renders text with a checkbox alongside it.
 */
export const VerifiedText: FC<VerifiedTextProps> = ({ text }) => {
  return (
    <Group>
      <IconCircleCheck color="green" size={16} />
      <Text size="sm" c="gray.8">
        {text}
      </Text>
    </Group>
  );
};
