import React, { useState, useEffect } from 'react';

import { Group, Tabs, Text } from '@mantine/core';
import { DateTime } from 'luxon';

import { CountBadge } from '../../../components/CountBadge';
import { useInSchedulingMeetings } from '../api/getInSchedulingMeetings';
import { useMeetings } from '../api/getMeetings';

import { GroupedMeetings } from './GroupedMeetings';
import { InSchedulingList } from './InSchedulingList';
import styles from './UpcomingMeetings.module.css';
import { EventState } from '../types';
import { PausedList } from './PausedList';

interface UpcomingMeetingsProps {
  start: DateTime;
  end: DateTime;
}

type UpcomingMeetingTab = 'inScheduling' | 'confirmed' | 'paused';

export const UpcomingMeetings: React.FC<UpcomingMeetingsProps> = ({ start, end }) => {
  const [activeTab, setActiveTab] = useState<UpcomingMeetingTab | null>('inScheduling');
  const { data: inSchedulingMeetings, refetch: refetchInSchedulingMeetings } = useInSchedulingMeetings();
  const { data: confirmedMeetings } = useMeetings({
    start: start.toISO() as string,
    end: end.toISO() as string,
  });

  useEffect(() => {
    // Refetch data when tab changes
    refetchInSchedulingMeetings();
  }, [activeTab, refetchInSchedulingMeetings]);

  const pausedMeetings = inSchedulingMeetings?.filter((meeting) => meeting.eventState === EventState.PAUSED);
  const pausedCount = pausedMeetings?.length || 0;
  const inSchedulingCount = (inSchedulingMeetings?.length || 0) - pausedCount;
  const confirmedCount = confirmedMeetings?.totalCount || 0;

  if (!inSchedulingMeetings || !confirmedMeetings) {
    return null;
  }

  return (
    <Tabs value={activeTab} onChange={(value) => setActiveTab(value as UpcomingMeetingTab)}>
      <Tabs.List
        className={styles.tabsList}
        style={{ position: 'sticky', top: 0, zIndex: 1, backgroundColor: 'var(--mantine-color-body)' }}
      >
        <Tabs.Tab value="inScheduling" size="xl">
          <Group gap="xs" wrap="nowrap">
            <CountBadge count={inSchedulingCount} size="lg" color="yellow" />
            <Text>Being Scheduled</Text>
          </Group>
        </Tabs.Tab>
        <Tabs.Tab value="confirmed" size="xl">
          <Group gap="xs" wrap="nowrap">
            <CountBadge count={confirmedCount} size="lg" color="blue" />
            <Text>Confirmed</Text>
          </Group>
        </Tabs.Tab>
        <Tabs.Tab value="paused" size="xl">
          <Group gap="xs" wrap="nowrap">
            <CountBadge count={pausedCount} size="lg" color="gray" />
            <Text>Paused</Text>
          </Group>
        </Tabs.Tab>
      </Tabs.List>

      <Tabs.Panel value="inScheduling">
        <InSchedulingList />
      </Tabs.Panel>

      <Tabs.Panel value="confirmed">
        <GroupedMeetings start={start} end={end} order="ASC" />
      </Tabs.Panel>

      <Tabs.Panel value="paused">
        <PausedList />
      </Tabs.Panel>
    </Tabs>
  );
};
