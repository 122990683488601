import { Divider, Stack, Title } from '@mantine/core';

import { MeetingFormat, PreferenceSettings } from '../types';
import { TravelTime } from './TravelTime';
import { MeetingTitlePrefix } from './MeetingTitlePrefix';
import { MeetingDuration } from './MeetingDuration';

const type = MeetingFormat.InPerson;

interface InPersonPreferencesProps {
  initialPreferences: PreferenceSettings;
}

export const InPersonPreferences = ({ initialPreferences }: InPersonPreferencesProps) => {
  return (
    <Stack gap="xl">
      <Title order={2}>
        In-Person Meeting Preferences
      </Title>
      <TravelTime initialPreferences={initialPreferences} />
      <Divider />
      <MeetingDuration initialPreferences={initialPreferences} label={type} />
      <Divider />
      <MeetingTitlePrefix initialPreferences={initialPreferences} type={type} />
    </Stack>
  );
}; 
