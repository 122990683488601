import { FC } from 'react';

import { Flex, Paper, Text } from '@mantine/core';

interface UserMessageProps {
  content: string;
}

export const UserMessage: FC<UserMessageProps> = ({ content }) => {
  return (
    <Flex justify="flex-end" w="100%">
      <Paper p="sm" radius="md" bg="white" shadow="sm" maw="80%">
        <Text size="sm" style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
          {content}
        </Text>
      </Paper>
    </Flex>
  );
};
