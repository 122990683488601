import { Divider, Stack, Title } from '@mantine/core';

import { MeetingFormat, PreferenceSettings } from '../types';
import { VirtualMeetings } from './VirtualMeetings';
import { MeetingTitlePrefix } from './MeetingTitlePrefix';
import { MeetingDuration } from './MeetingDuration';

const type = MeetingFormat.Remote;

interface RemotePreferencesProps {
  initialPreferences: PreferenceSettings;
}

export const RemotePreferences = ({ initialPreferences }: RemotePreferencesProps) => {
  return (
    <Stack gap="xl">
        <Title order={2}>
            Remote Meeting Preferences
        </Title>
        <VirtualMeetings initialPreferences={initialPreferences} />
        <Divider />
        <MeetingDuration initialPreferences={initialPreferences} label={type} />
        <Divider />
        <MeetingTitlePrefix initialPreferences={initialPreferences} type={type} />
    </Stack>
  );
};
