import { FC } from 'react';

import { Button } from '@mantine/core';
import { IconMail } from '@tabler/icons-react';

import { useTryBlockitLink } from '@hooks/useTryBlockitLink';

export const TryBlockit: FC = () => {
  const mailToLink = useTryBlockitLink();

  if (!mailToLink) {
    return null;
  }

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    window.location.href = mailToLink;
  };

  return (
    <Button
      onClick={handleClick}
      fullWidth
      color="blue"
      leftSection={<IconMail size="1.5rem" />}
      variant="filled"
      h={40}
      fz="sm"
      styles={{
        inner: {
          height: '100%',
          display: 'flex',
          alignItems: 'center',
        },
      }}
    >
      Try Blockit
    </Button>
  );
};
