import React from 'react';

import { IconHome, IconCoffee, IconBriefcase, IconProps, IconBowlSpoon, IconBurger, IconGlassCocktail, IconToolsKitchen2, IconBread } from '@tabler/icons-react';

import { LocationType } from '../types';

interface LocationTypeIconProps extends IconProps {
  locationType: LocationType;
}

const locationTypeToIcon = {
  [LocationType.HOME]: IconHome,
  [LocationType.OFFICE]: IconBriefcase,
  [LocationType.COFFEE]: IconCoffee,
  [LocationType.BREAKFAST]: IconBowlSpoon,
  [LocationType.BRUNCH]: IconBread,
  [LocationType.LUNCH]: IconBurger,
  [LocationType.DINNER]: IconToolsKitchen2,
  [LocationType.DRINKS]: IconGlassCocktail,
};

export const LocationTypeIcon: React.FC<LocationTypeIconProps> = ({ locationType, ...props }) => {
  const Icon = locationTypeToIcon[locationType];
  return <Icon size={16} {...props} />;
};
