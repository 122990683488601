import { FC } from 'react';

import { Box } from '@mantine/core';
import { motion } from 'framer-motion';

export const LoadingDots: FC = () => {
  const dotVariants = {
    hidden: { opacity: 0.3 },
    visible: { opacity: 1 },
  };

  const containerVariants = {
    initial: { transition: { staggerChildren: 0.2 } },
    animate: { transition: { staggerChildren: 0.2 } },
  };

  return (
    <Box component={motion.span} variants={containerVariants} initial="initial" animate="animate" display="inline-flex">
      {[0, 1, 2].map((i) => (
        <motion.span
          key={i}
          variants={dotVariants}
          initial="hidden"
          animate="visible"
          transition={{
            duration: 0.6,
            repeat: Infinity,
            repeatType: 'reverse',
          }}
        >
          .
        </motion.span>
      ))}
    </Box>
  );
};
