import { useCallback, useEffect, useRef } from 'react';

import { Loader } from '@mantine/core';
import * as Sentry from '@sentry/react';
import { AxiosError } from 'axios';
import { useSearchParams } from 'react-router-dom';

import { PageContainer } from '@components/PageContainer';
import { useLogPageView } from '@features/analytics';
import { axios } from '@lib/axios';
import { PUBLIC_ROUTES } from '@routes/enums';

/**
 * The Microsoft OAuth popup redirects to this page after the user authorizes
 * a calendar.
 */
export const TempMicrosoftOAuthPage = () => {
  const [searchParams] = useSearchParams();
  useLogPageView('Microsoft OAuth for Waitlist');

  const hasCheckedAuthorization = useRef(false);

  const checkAuthorization = useCallback(async () => {
    try {
      const code = searchParams.get('code');
      const redirectUri = `${window.location.origin}${PUBLIC_ROUTES.MICROSOFT_OAUTH_READONLY}`;
      const verifier = searchParams.get('state');

      if (!code) {
        throw new Error('missing code or state');
      }

      const requestBody = {
        code: code,
        codeVerifier: verifier ?? '',
        redirectUri: redirectUri,
      };

      const response = await axios.post('/account/temporary-oauth-microsoft', new URLSearchParams(requestBody), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });

      console.log('response', response);
      const { accessToken, userProfile } = response.data;

      if (response.status !== 200) {
        throw new Error('Failed to fetch access token');
      }

      if (!accessToken || !userProfile) {
        throw new Error('Access token not found in response');
      }

      if (window.opener) {
        window.opener.postMessage(
          { type: 'microsoft-oauth-success', accessToken, userProfile },
          window.location.origin,
        );
      } else {
        Sentry.captureException(new Error('Window opener not found. This page should be opened as a popup.'));
      }
      window.close();
    } catch (err) {
      if (err instanceof AxiosError && err.response?.data) {
        window.opener.postMessage(
          { type: 'microsoft-account-added-error', message: err.response.data.message },
          window.location.origin,
        );
      } else {
        window.opener.postMessage(
          { type: 'microsoft-account-added-error', message: 'Unable to add account. Please try again.' },
          window.location.origin,
        );
      }
    }
    // window.close();
  }, [searchParams]);

  useEffect(() => {
    if (!hasCheckedAuthorization.current) {
      hasCheckedAuthorization.current = true;
      checkAuthorization();
    }
  }, [checkAuthorization]);

  return (
    <PageContainer>
      <Loader size={30} />
    </PageContainer>
  );
};
