import React from 'react';

import { Box, Divider, Stack, Text } from '@mantine/core';

import { useInSchedulingMeetings } from '../api/getInSchedulingMeetings';
import { EventState, InSchedulingMeeting } from '../types';

import { InSchedulingItem } from './InSchedulingItem';

export const PausedList: React.FC = () => {
  const { data: meetings } = useInSchedulingMeetings();

  const pausedEmptyState = (
    <Box mt="md">
      <Text>No meetings are currently paused.</Text>
    </Box>
  );

  if (!meetings || meetings.length === 0) {
    return pausedEmptyState;
  }

  const pausedMeetings = meetings.filter((meeting) => meeting.eventState === EventState.PAUSED);
  if (pausedMeetings.length === 0) {
    return pausedEmptyState;
  }

  return (
    <Box mt="md">
      <Stack gap="md">
        <Text size="lg" fw={700}>
          Paused Meetings
        </Text>
        <Stack gap="xs">
          {pausedMeetings.map((meeting: InSchedulingMeeting, index: number) => (
            <React.Fragment key={meeting.id}>
              <InSchedulingItem meeting={meeting} />
              {index < pausedMeetings.length - 1 && <Divider />}
            </React.Fragment>
          ))}
        </Stack>
      </Stack>
    </Box>
  );
};
