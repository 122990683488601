import { FC } from 'react';

import { Box, Flex, Stack, Text } from '@mantine/core';
import Markdown from 'react-markdown';

import { StreamedText } from '@components/StreamedText';
import { OnboardingFieldNames } from '@features/onboarding/types';

import { OnboardingCompleteButtons } from './OnboardingCompleteButtons';
import { SpecializedInstructions } from './SpecializedInstructions';

// NOTE this doesn't really work in all cases because sometimes the screen can be really small,
// but worst case there is more padding than needed
const AVERAGE_CHARACTERS_PER_LINE = 30;
const LINE_HEIGHT_ESTIMATE = 20;
const EXTRA_HEIGHT_FOR_LAST_MESSAGE = 120;

interface AssistantMessageProps {
  content: string;
  isLastMessage: boolean;
  currentQuestionFieldName: OnboardingFieldNames | null;
  streamingCallback?: () => void;
  isResponding: boolean;
  onboardingComplete: boolean;
}

export const AssistantMessage: FC<AssistantMessageProps> = ({
  content,
  isLastMessage,
  currentQuestionFieldName,
  streamingCallback,
  isResponding,
  onboardingComplete,
}) => {
  return (
    <Flex
      justify="flex-start"
      maw="80%"
      ml={0}
      mih={
        isLastMessage
          ? (content.length / AVERAGE_CHARACTERS_PER_LINE) * LINE_HEIGHT_ESTIMATE + EXTRA_HEIGHT_FOR_LAST_MESSAGE
          : undefined
      }
    >
      {isLastMessage ? (
        <Stack gap="0" pt={0}>
          <StreamedText c="blockitBlue" fw={500} text={content} size="sm" delay={20} onComplete={streamingCallback} />
          {!isResponding && (
            <Box pl="26">
              {onboardingComplete ? (
                <OnboardingCompleteButtons />
              ) : (
                <SpecializedInstructions currentQuestionFieldName={currentQuestionFieldName} />
              )}
            </Box>
          )}
        </Stack>
      ) : (
        <Box pl="26">
          <Text c="blockitBlue" fw={500} size="sm" component="span">
            <Markdown>{content}</Markdown>
          </Text>
        </Box>
      )}
    </Flex>
  );
};
