import { FC } from 'react';

import { Divider, Paper, Stack, Text } from '@mantine/core';
import { IconBell, IconCreditCard, IconUser } from '@tabler/icons-react';

import { SideContent } from '@components/SideContent';
import { useUser } from '@features/users';

import { BillingDetails } from './BillingDetails';
import { SectionHeader } from './SectionHeader';
import { SettingsForm } from './SettingsForm';
import { UserInfoForm } from './UserInfoForm';

export const AccountsPage: FC = () => {
  const { data: userData } = useUser();
  if (!userData) {
    return null;
  }
  return (
    <SideContent sideContent={<Text c="dimmed">Manage the global settings for your account.</Text>}>
      <Paper withBorder radius="md" p="xl">
        <Stack gap="lg">
          <SectionHeader icon={IconUser} title="Personal Information" />
          <UserInfoForm initialUserData={userData} />

          <Divider my="md" />

          <SectionHeader icon={IconBell} title="Notification Preferences" />
          <SettingsForm />

          <Divider my="md" />

          <SectionHeader icon={IconCreditCard} title="Billing Details" />
          <BillingDetails />
        </Stack>
      </Paper>
    </SideContent>
  );
};
