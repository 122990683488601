import React from 'react';

import { Box, Button, Stack, Table } from '@mantine/core';
import { IconCirclePlus, IconEdit, IconTrash } from '@tabler/icons-react';

import { TooltipActionIcon } from '@components/TooltipActionIcon';
import { DAY_OF_WEEK_LABELS_ABBREVIATED } from '@constants/index';

import { useDeletePointOfInterest } from '../api/deletePointOfInterest';
import { PointOfInterest } from '../types';
import { formatLocationAddress } from '../utils/formatLocationAddress';

import { LocationTypeBadge } from './LocationTypeBadge';

interface PointOfInterestTableProps {
  pointsOfInterest: PointOfInterest[];
  onAddOrEditPointOfInterest: (pointOfInterest?: PointOfInterest) => void;
  includeAddButton?: boolean;
}

export const PointOfInterestTable: React.FC<PointOfInterestTableProps> = ({
  pointsOfInterest,
  onAddOrEditPointOfInterest,
  includeAddButton = true,
}) => {
  const { mutate: deletePointOfInterest } = useDeletePointOfInterest();

  return (
    <Stack>
      <Table.ScrollContainer minWidth={400}>
        <Table captionSide="bottom">
          {pointsOfInterest.length === 0 && (
            <Table.Caption>
              Add a preferred location so that Blockit can schedule in person meetings for you.
            </Table.Caption>
          )}
          <Table.Thead>
            <Table.Tr>
              <Table.Th>Type</Table.Th>
              <Table.Th>Name</Table.Th>
              <Table.Th>Location</Table.Th>
              <Table.Th>Schedule</Table.Th>
              <Table.Th>Actions</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {pointsOfInterest.map((poi) => (
              <Table.Tr key={poi.id}>
                <Table.Td>
                  <LocationTypeBadge locationType={poi.locationType} />
                </Table.Td>
                <Table.Td>{poi.name}</Table.Td>
                <Table.Td>{formatLocationAddress(poi.location)}</Table.Td>
                <Table.Td>
                  {poi.anchorAvailability
                    .sort()
                    .map((day) => DAY_OF_WEEK_LABELS_ABBREVIATED[day])
                    .join(', ')}
                </Table.Td>
                <Table.Td>
                  <TooltipActionIcon
                    onClick={() => onAddOrEditPointOfInterest(poi)}
                    variant="subtle"
                    icon={<IconEdit size={16} />}
                  />
                  <TooltipActionIcon
                    onClick={() => deletePointOfInterest(poi.id)}
                    variant="subtle"
                    icon={<IconTrash size={16} />}
                    color="red"
                  />
                </Table.Td>
              </Table.Tr>
            ))}
          </Table.Tbody>
        </Table>
      </Table.ScrollContainer>
      {includeAddButton && (
        <Box>
          <Button
            variant="outline"
            leftSection={<IconCirclePlus size={16} />}
            onClick={() => onAddOrEditPointOfInterest()}
          >
            Add location
          </Button>
        </Box>
      )}
    </Stack>
  );
};
