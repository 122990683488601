import { FC } from 'react';

import { Box, Container, Grid, ScrollArea, Select, Stack } from '@mantine/core';
import { useLocation, useNavigate } from 'react-router-dom';

import { LinkInfo } from '@/types';
import { getActiveLink } from '@utils/getActiveLink';

import { NavLink } from './NavLink';
import { PageTitle } from './PageTitle';
import classes from './SideContent.module.css';

interface SideContentProps {
  children: React.ReactNode;
  sideContent: React.ReactNode;
  subLinks?: LinkInfo[];
  sideContentSize?: string;
  showFullWidth?: boolean;
}

/**
 * Renders content to the left side of the children on medium+ screens and
 * renders content above in small screens.
 */
export const SideContent: FC<SideContentProps> = (props) => {
  const { children, sideContent, subLinks, sideContentSize, showFullWidth } = props;
  const location = useLocation();
  const navigate = useNavigate();

  const activeLink = getActiveLink(subLinks?.map((link) => link.to) || [], location.pathname);
  const leftContentWidth = sideContentSize ?? '200';

  return (
    <Box pos="relative" h="calc(100vh - 100px)">
      <Grid gutter="md">
        <Grid.Col span={{ base: 12, xl: 'content' }}>
          <Box
            pos={{ xl: 'fixed' }}
            w={{ base: '100%', xl: leftContentWidth }}
            h={{ xl: 'calc(100vh - 100px)' }}
            style={{ zIndex: 3, backgroundColor: 'white' }}
          >
            <Stack h="100%">
              <PageTitle />
              <ScrollArea offsetScrollbars scrollbars="y" style={{ overflowX: 'hidden' }}>
                <Box>{sideContent}</Box>
                <Box>
                  {subLinks?.map((link) => (
                    <NavLink key={link.to} label={link.label} to={link.to} active={link.to === activeLink} />
                  ))}
                </Box>
                {subLinks && (
                  <Select
                    clearable={false}
                    data={subLinks.map((link) => ({ label: link.label, value: link.to }))}
                    allowDeselect={false}
                    value={activeLink}
                    hiddenFrom="md"
                    onChange={(value) => (value ? navigate(value) : null)}
                    size="md"
                  />
                )}
              </ScrollArea>
            </Stack>
          </Box>
        </Grid.Col>

        <Grid.Col span={{ base: 12, md: 'auto' }} ml={{ xl: leftContentWidth }}>
          <Box mt="xs" className={classes.childrenContainer}>
            <Container size="xl" ml={0} pb="xs" fluid={showFullWidth}>
              {children}
            </Container>
          </Box>
        </Grid.Col>
      </Grid>
    </Box>
  );
};
