import { useState, ReactNode } from 'react';

import { ActionIcon, Box } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconCheck, IconCopy } from '@tabler/icons-react';

interface WithCopyButtonProps {
  value: string;
  copyId: string;
  children: ReactNode;
}

export const WithCopyButton = ({ value, copyId, children }: WithCopyButtonProps) => {
  const [copiedField, setCopiedField] = useState<string | null>(null);

  const handleCopy = async (text: string, fieldId: string) => {
    try {
      await navigator.clipboard.writeText(text);
      setCopiedField(fieldId);
      notifications.show({
        message: 'Copied to clipboard',
        color: 'green',
      });
      setTimeout(() => setCopiedField(null), 2000);
    } catch (err) {
      notifications.show({
        message: 'Failed to copy to clipboard',
        color: 'red',
      });
    }
  };

  return (
    <Box pos="relative">
      {children}
      <ActionIcon
        variant="subtle"
        color={copiedField === copyId ? 'green' : 'gray'}
        onClick={() => handleCopy(value, copyId)}
        title="Copy to clipboard"
        pos="absolute"
        top={4}
        right={4}
      >
        {copiedField === copyId ? <IconCheck size={16} /> : <IconCopy size={16} />}
      </ActionIcon>
    </Box>
  );
};
