import { TextInput, Text } from '@mantine/core';
import { notifications } from '@mantine/notifications';

import { useUpdatePreferences } from '../api/updatePreferences';
import { MeetingFormat, PreferenceSettings } from '../types';
import { SettingsGrid } from './SettingsGrid';
import { useState } from 'react';
import { IconAbc } from '@tabler/icons-react';
import { useDebouncedCallback } from '@mantine/hooks';
import { AnalyticsEventName, track } from '@features/analytics';
import { useUser } from '@features/users';

export const MeetingTitlePrefix = ({ initialPreferences, type }: { initialPreferences: PreferenceSettings, type: MeetingFormat }) => {
  const { data: user } = useUser();
  const firstName = user?.firstName || "You";

  const { mutate: updatePreferences } = useUpdatePreferences({
    onSuccess: () => {
        track({
            type: AnalyticsEventName.PreferencesUpdated,
            data: { preference: `${type === MeetingFormat.InPerson ? 'inPersonMeetingTitlePrefix' : 'remoteMeetingTitlePrefix'} Meeting Title Prefix`, isDefault: initialPreferences.isDefault },
        });
        notifications.show({ message: `${type} meeting title prefix updated`, color: 'green', autoClose: 3000 });
    },
  });
  const [meetingTitlePrefix, setMeetingTitlePrefix] = useState((type === MeetingFormat.InPerson ? initialPreferences.inPersonMeetingTitlePrefix : initialPreferences.remoteMeetingTitlePrefix) || '');

  const debouncedUpdateMeetingTitlePrefix = useDebouncedCallback((value: string) => {
    const newValue = value.trim(); // ensures there is no space after the prefix
    setMeetingTitlePrefix(newValue);
    if (type === MeetingFormat.InPerson) {
      updatePreferences({ ...initialPreferences, inPersonMeetingTitlePrefix: newValue });
    } else {
      updatePreferences({ ...initialPreferences, remoteMeetingTitlePrefix: newValue });
    }
  }, 500);
  
  const handleMeetingTitlePrefixChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setMeetingTitlePrefix(value);
    debouncedUpdateMeetingTitlePrefix(value);
  };

  return (
    <SettingsGrid
        Icon={IconAbc}
        title="Optional: Custom Meeting Title Prefix"
        description="Personalize the prefix in your meeting title. Leave blank for no prefix."
        left={
          <>
              <TextInput
                  placeholder="E.g. 'Product Demo: '"
                  value={meetingTitlePrefix}
                  onChange={handleMeetingTitlePrefixChange}
              />
              <Text c="dimmed">
                <Text component="span" c="dark" fs="italic">Title Preview: </Text>
                <Text component="span" fw={700} c="dimmed">
                  {meetingTitlePrefix === '' ? "[Your Meeting Title Prefix]" : meetingTitlePrefix}
                </Text>
                <Text component="span" c="dimmed"> Recipient (Recipient Company) / {firstName} (Your Company)</Text>
              </Text>
          </>
        }
    />
  );
};
