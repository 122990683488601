import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Box, Button, Stack, Title } from '@mantine/core';
import { AnimatePresence, motion } from 'framer-motion';

import { BlockitLogo } from '@components/BlockitLogo';
import { TypewriterLine } from '@components/TypewriterLine';

import styles from './IntroMessage.module.css';

const ICON_HEIGHT = 24;
const TEXT_NEW_LINE_DELAY = 300;
const LOGO_ANIMATION_DURATION_IN_MS = 300;

interface IntroMessageProps {
  onBeginClick: () => void;
}

export const IntroMessage: React.FC<IntroMessageProps> = ({ onBeginClick }) => {
  const [currentLineIndex, setCurrentLineIndex] = useState(0);
  const texts = [
    "Hello, I'm Blockit!",
    'Wonderful to meet you.',
    "Typically, it takes an EA 3-6 months to learn someone's preferences.",
    "I'm going to try in 3-6 minutes 🙂",
  ];

  const lineRefs = useRef<(HTMLDivElement | null)[]>([]);
  const [iconPosition, setIconPosition] = useState<number | null>(null);
  const [iconXPosition, setIconXPosition] = useState<number | null>(null);

  const updateIconPosition = useCallback(() => {
    const currentRef = lineRefs.current[currentLineIndex];
    if (currentRef) {
      const rect = currentRef.getBoundingClientRect();
      const containerRect = lineRefs.current[0]?.parentElement?.getBoundingClientRect();
      if (containerRect) {
        const relativePosition = rect.top - containerRect.top + (rect.height - ICON_HEIGHT) / 2;
        setIconPosition(relativePosition);
      }
    }
  }, [currentLineIndex]);

  useEffect(() => {
    requestAnimationFrame(() => {
      const firstLineRef = lineRefs.current[0];
      if (firstLineRef && firstLineRef.parentElement) {
        const rect = firstLineRef.getBoundingClientRect();
        const containerRect = firstLineRef.parentElement.getBoundingClientRect();
        const initialPosition = rect.top - containerRect.top + (rect.height - ICON_HEIGHT) / 2;
        setIconPosition(initialPosition);
        setIconXPosition(-2 * ICON_HEIGHT);
      }
    });
  }, []);

  useEffect(() => {
    updateIconPosition();
  }, [currentLineIndex, updateIconPosition]);

  useEffect(() => {
    window.addEventListener('resize', updateIconPosition);
    return () => window.removeEventListener('resize', updateIconPosition);
  }, [updateIconPosition]);

  const handleLineComplete = () => {
    setTimeout(() => {
      setCurrentLineIndex((prev) => prev + 1);
    }, TEXT_NEW_LINE_DELAY);
  };

  return (
    <Stack pos="relative" p="120px">
      <Box className={styles.textContainer} maw="460px" mx="auto" w="100%" mih="320px">
        <AnimatePresence mode="wait">
          {iconPosition !== null && iconXPosition !== null && (
            <motion.div
              key="blockit-logo"
              initial={{ y: iconPosition, x: iconXPosition }}
              animate={{ y: iconPosition, x: iconXPosition }}
              transition={{ duration: LOGO_ANIMATION_DURATION_IN_MS / 1000 }}
              className={styles.iconContainer}
            >
              <BlockitLogo size={ICON_HEIGHT} />
            </motion.div>
          )}
        </AnimatePresence>
        {texts.slice(0, currentLineIndex + 1).map((text, index) => (
          <Title
            key={index}
            ref={(el) => (lineRefs.current[index] = el)}
            order={index === 0 ? 2 : 3}
            c="blue.8"
            fw={index === 0 ? 700 : 500}
            mt="lg"
            component="div"
            className={styles.titleContainer}
          >
            <TypewriterLine
              text={text}
              onComplete={index === currentLineIndex ? handleLineComplete : undefined}
              delay={LOGO_ANIMATION_DURATION_IN_MS + 100}
            />
          </Title>
        ))}
        {currentLineIndex === texts.length && (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, ease: 'easeOut' }}
          >
            <Button mt="xl" onClick={onBeginClick}>
              Let&apos;s Begin
            </Button>
          </motion.div>
        )}
      </Box>
    </Stack>
  );
};
