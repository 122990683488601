import { FC } from 'react';

import { Box, Button, Image, Stack, Text } from '@mantine/core';

import CustomRemoteMeetingLinkImage from '../../assets/CustomMeetingLinkInstructions.png';

export const CustomRemoteMeetingInstructions: FC = () => {
  return (
    <Stack py="md" gap="xs">
      <Text c="blockitBlue" size="sm" fw={600}>
        Personal meeting room instructions:
      </Text>
      <Text c="blockitBlue" size="sm" fw={500}>
        1. Navigate to the provided link
        <br />
        2. Select the &quot;Personal Room&quot; tab
        <br />
        3. Copy your personal meeting link
        <br />
        4. Paste it here
      </Text>
      <Image src={CustomRemoteMeetingLinkImage} alt="Custom remote meeting link" />
      <Box>
        <Button component="a" href="https://zoom.us/meeting#/upcoming" target="_blank" size="">
          Copy your personal link
        </Button>
      </Box>
    </Stack>
  );
};
