import { EventInput } from '@fullcalendar/core';

import { WorkingHourSettings } from '../types';

import {
  checkIfEventsOverlapAndShouldNotMerge,
  isEventOutsideWorkingHours,
  isEventOnOneDay,
  AvaibilityEvent,
} from './fullCalendar';

export interface ValidationError {
  title: string;
  message: string;
  color: string;
}

export const validateEvent = (
  event: EventInput,
  existingEvents: AvaibilityEvent[],
  workingHours?: WorkingHourSettings,
): ValidationError | null => {
  const title = 'Error';
  const color = 'red';

  // Check if event spans multiple days
  if (!isEventOnOneDay(event)) {
    return {
      title,
      message: 'Availability blocks cannot span multiple days.',
      color,
    };
  }

  // Check if event is within working hours
  if (workingHours && isEventOutsideWorkingHours(event, workingHours)) {
    return {
      title,
      message: 'Availability blocks cannot be outside working hours.',
      color,
    };
  }
  console.log('event', event);
  console.log('existingEvents', existingEvents);

  // Check for overlapping events
  const hasOverlappingEvents = existingEvents.some((existingEvent) =>
    checkIfEventsOverlapAndShouldNotMerge(existingEvent, event),
  );

  if (hasOverlappingEvents) {
    return {
      title,
      message: 'Blocks of different types cannot overlap.',
      color,
    };
  }

  return null;
};
