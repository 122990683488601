import { Text } from '@mantine/core';

import { SideContent } from '@components/SideContent';

import { TriggerPhraseForm } from './TriggerPhraseForm';

export const TriggerPhrasesPage = () => {
  return (
    <SideContent sideContent={<Text c="dimmed">Trigger Blockit to take special actions by using codewords.</Text>}>
      <TriggerPhraseForm />
    </SideContent>
  );
};
