import { FC, useState } from 'react';

import { Button, Checkbox, Group, Popover, Stack, Text } from '@mantine/core';
import { useClickOutside, useDisclosure } from '@mantine/hooks';
import { IconSquares } from '@tabler/icons-react';

import { DayOfWeek } from '@/types';
import { TooltipActionIcon } from '@components/TooltipActionIcon';
import { DAY_OF_WEEK_LABELS } from '@constants/index';
import { flipObject } from '@utils/flipObject';

import { WorkingHourSettings } from '../types';

const LABEL_TO_DAY = flipObject(DAY_OF_WEEK_LABELS);

interface CopyPreferenceHoursDropdownProps {
  currentDayIndex: DayOfWeek;
  onSubmit: (daysToCopy: DayOfWeek[]) => void;
  workingHours?: WorkingHourSettings;
}

export const CopyPreferenceHoursDropdown: FC<CopyPreferenceHoursDropdownProps> = ({
  currentDayIndex,
  onSubmit,
  workingHours,
}) => {
  const [opened, { close, toggle }] = useDisclosure(false);
  const [dropdown, setDropdown] = useState<HTMLDivElement | null>(null);
  const [control, setControl] = useState<HTMLButtonElement | null>(null);

  useClickOutside(() => close(), null, [control, dropdown]);

  // Get only days that have working hours
  const workingDays = Object.entries(workingHours || {})
    .filter(([_, hours]) => hours && hours.length > 0)
    .map(([day]) => day as DayOfWeek);

  // By default, we copy all the working days after the current day until the weekend
  const [daysToCopy, setDaysToCopy] = useState<DayOfWeek[]>(
    workingDays
      .filter((day) => parseInt(day) > parseInt(currentDayIndex) && parseInt(day) < 6)
      .concat([currentDayIndex]),
  );

  const handleSubmit = () => {
    onSubmit(daysToCopy);
    // clear the selections
    setDaysToCopy([currentDayIndex]);
    close();
  };

  const handleChange = (days: string[]) => {
    setDaysToCopy(days.map((day) => LABEL_TO_DAY[day] as DayOfWeek));
  };

  return (
    <Popover
      width={200}
      position="bottom"
      withArrow
      shadow="md"
      opened={opened}
      middlewares={{ flip: true, shift: true, inline: true }}
    >
      <Popover.Target>
        <TooltipActionIcon
          size="xs"
          icon={<IconSquares size={12} />}
          title="Copy to other days"
          onClick={toggle}
          ref={setControl}
          color="gray.5"
        />
      </Popover.Target>
      <Popover.Dropdown ref={setDropdown}>
        <Checkbox.Group value={daysToCopy.map((day) => DAY_OF_WEEK_LABELS[day])} onChange={handleChange}>
          <Stack gap="xs">
            {Object.entries(DAY_OF_WEEK_LABELS)
              .filter(([dayIndex]) => workingDays.includes(dayIndex as DayOfWeek))
              .map(([dayIndex, dayLabel]) => (
                <Group key={dayLabel} justify="space-between">
                  <Text>{dayLabel}</Text>
                  <Checkbox disabled={dayIndex === currentDayIndex} value={dayLabel} />
                </Group>
              ))}
            <Button onClick={handleSubmit}>Copy times</Button>
          </Stack>
        </Checkbox.Group>
      </Popover.Dropdown>
    </Popover>
  );
};
