import * as Sentry from '@sentry/react';

import { axios } from '@lib/axios';

interface SSEStreamOptions<T extends object, K extends object> {
  url: string;
  messageBody: string;
  onMessage?: (data: T) => void;
  onError?: (error: Error) => void;
  onComplete?: (data: K) => void;
}

export const createSSEStream = <T extends object, K extends object>({
  url,
  messageBody,
  onMessage,
  onError,
  onComplete,
}: SSEStreamOptions<T, K>) => {
  const abortController = new AbortController();

  const handleError = (err: unknown) => {
    if (err instanceof Error && err.name === 'CanceledError') {
      return;
    }

    if (err instanceof Error) {
      Sentry.captureException(err);
    }

    const errorMessage = err instanceof Error ? err.message : 'Failed to connect to stream';

    onError?.(new Error(errorMessage));
  };

  const streamPromise = axios.post(
    url,
    { messageBody },
    {
      params: {
        silent: true,
      },
      signal: abortController.signal,
      responseType: 'text',
      headers: {
        Accept: 'text/event-stream',
        'Cache-Control': 'no-cache',
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onDownloadProgress: (progressEvent: any) => {
        if (abortController.signal.aborted) {
          return;
        }

        try {
          const chunk = progressEvent.event.target.response;
          if (!chunk) return;

          const lines = chunk.split('\n');
          lines.forEach((line: string) => {
            if (abortController.signal.aborted || !line.trim()) {
              return;
            }
            if (line.startsWith('data: ')) {
              const data = JSON.parse(line.slice(6));
              const content = data.content;
              const type = data.type;

              if (type === 'complete') {
                onComplete?.(content);
                abortController.abort();
                return;
              } else if (type === 'chunk') {
                onMessage?.(content);
              }
            } else {
              throw new Error(`Unknown event type: ${line}`);
            }
          });
        } catch (err) {
          handleError(err);
          abortController.abort();
        }
      },
    },
  );

  return {
    abort: () => {
      abortController.abort();
    },
    promise: streamPromise.catch(handleError),
  };
};
