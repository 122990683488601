import { DateTime } from 'luxon';

import { AvaibilityEvent, checkIfEventsShouldMerge, convertDateInputToDateTime } from './fullCalendar';

/**
 * Merges a new event with existing events if they should be merged based on time overlap and compatibility.
 * This utility is used when adding new events to the calendar or copying events across days.
 *
 * @param existingEvents - Array of existing events in the calendar
 * @param newEvent - The new event to potentially merge with existing events
 * @returns Updated array of events after merging
 */
export const mergeEvents = (existingEvents: AvaibilityEvent[], newEvent: AvaibilityEvent): AvaibilityEvent[] => {
  const eventsToMerge = existingEvents.filter((event) => checkIfEventsShouldMerge(event, newEvent));

  if (eventsToMerge.length === 0) {
    return [...existingEvents, newEvent];
  }

  // Calculate the new merged event's start and end times
  const start = DateTime.min(
    ...eventsToMerge.map((e) => convertDateInputToDateTime(e.start as Date)),
    convertDateInputToDateTime(newEvent.start as Date),
  ).toJSDate();

  const end = DateTime.max(
    ...eventsToMerge.map((e) => convertDateInputToDateTime(e.end as Date)),
    convertDateInputToDateTime(newEvent.end as Date),
  ).toJSDate();

  // Filter out the merged events and add the new merged event
  return [
    ...existingEvents.filter((e) => !eventsToMerge.includes(e)),
    {
      ...newEvent,
      start,
      end,
    },
  ];
};
