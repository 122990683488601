import React from 'react';

import { Container } from '@mantine/core';
import { AnimatePresence, motion } from 'framer-motion';
import { useLocation, useNavigate } from 'react-router-dom';

import { GradientBackground } from '@components/GradientBackground';

import { OnboardingRoutes, OnboardingStep } from '../types';

import { IntroMessage } from './IntroMessage';
import { OnboardingAgentContainer } from './OnboardingAgentContainer';

interface OnboardingPageProps {
  initialStep?: OnboardingStep | null;
  title?: string;
}

export const OnboardingPage: React.FC<OnboardingPageProps> = ({ initialStep = null }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isIntroRoute = location.pathname === OnboardingRoutes.INTRO || location.pathname === OnboardingRoutes.ROOT;

  const handleBeginClick = () => {
    navigate(OnboardingRoutes.CALENDARS);
  };

  return (
    <GradientBackground>
      <Container h="100vh" size="xl" p={{ base: 'sm', sm: 'xl' }}>
        <AnimatePresence mode="wait">
          {isIntroRoute ? (
            <motion.div
              key="introduction"
              initial={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3 }}
            >
              <IntroMessage onBeginClick={handleBeginClick} />
            </motion.div>
          ) : (
            <motion.div
              key="onboarding-flow"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.3 }}
            >
              <OnboardingAgentContainer initialStep={initialStep} />
            </motion.div>
          )}
        </AnimatePresence>
      </Container>
    </GradientBackground>
  );
};
