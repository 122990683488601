import { Badge, Group, Stack, Text } from '@mantine/core';

import { ConnectZoomButton } from '@features/auth/components/ConnectZoomButton';

import { useZoomAccounts } from '../api/getZoomAccounts';
import { ZoomAccountState } from '../types';

export function ZoomIntegrationContainer() {
  const { data: zoomAccounts } = useZoomAccounts();

  const getStateColor = (state: ZoomAccountState) => {
    switch (state) {
      case ZoomAccountState.ACTIVE:
        return 'green';
      case ZoomAccountState.REFRESH_TOKEN_FAILED:
        return 'yellow';
      case ZoomAccountState.DEAUTHORIZED:
        return 'red';
    }
  };

  if (!zoomAccounts) {
    return null;
  }

  return (
    <Stack>
      {zoomAccounts && zoomAccounts.length > 0 ? (
        <>
          <Text fw={500}>Connected Zoom accounts:</Text>
          {zoomAccounts.map((account, index) => (
            <Group key={index} gap="xs">
              <Badge variant="dot" color={getStateColor(account.state)} size="lg">
                {account.email}
              </Badge>
            </Group>
          ))}
        </>
      ) : (
        <Text>No Zoom accounts connected.</Text>
      )}
      <ConnectZoomButton variant="outline" />
    </Stack>
  );
}
