import {
  GoogleOAuthPage,
  SlackOAuthPage,
  TempGoogleOAuthPage,
  MicrosoftOAuthPage,
  TempMicrosoftOAuthPage,
} from '@features/auth';
import { LoginPage } from '@features/onboarding';
import { WaitlistIntakePage } from '@features/waitlist-form';

import { PUBLIC_ROUTES } from './enums';

export const publicRoutes = [
  {
    path: PUBLIC_ROUTES.WAITLIST,
    element: <WaitlistIntakePage />,
  },
  {
    path: PUBLIC_ROUTES.LOGIN,
    element: <LoginPage />,
  },
  {
    path: PUBLIC_ROUTES.GOOGLE_OAUTH_READONLY,
    element: <TempGoogleOAuthPage />,
  },
  {
    path: PUBLIC_ROUTES.GOOGLE_OAUTH,
    element: <GoogleOAuthPage />,
  },
  {
    path: PUBLIC_ROUTES.SLACK_OAUTH,
    element: <SlackOAuthPage />,
  },
  {
    path: PUBLIC_ROUTES.MICROSOFT_OAUTH_READONLY,
    element: <TempMicrosoftOAuthPage />,
  },
  {
    path: PUBLIC_ROUTES.MICROSOFT_OAUTH,
    element: <MicrosoftOAuthPage />,
  },
];
