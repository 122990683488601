import { FC } from 'react';

import { Box, BoxProps } from '@mantine/core';
import { motion } from 'framer-motion';

import { BlockitLogo } from './BlockitLogo';

interface PulsatingBlockitLogoProps extends BoxProps {
  size?: number | string;
  duration?: number;
}

export const PulsatingBlockitLogo: FC<PulsatingBlockitLogoProps> = ({ size = 24, duration = 1.5, ...boxProps }) => {
  return (
    <Box
      component={motion.div}
      animate={{
        scale: [1, 0.8, 1],
        opacity: [0.7, 1, 0.7],
      }}
      transition={{
        duration,
        repeat: Infinity,
        ease: 'easeInOut',
      }}
      {...boxProps}
    >
      <BlockitLogo size={size} />
    </Box>
  );
};
