import { FC, useEffect, useState } from 'react';

import { ActionIcon, Box, Paper, Tooltip, useMantineTheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { IconMessageCircle } from '@tabler/icons-react';
import { AnimatePresence, motion } from 'framer-motion';
import { useLocation, useNavigate } from 'react-router-dom';

import { getStepLabel, ONBOARDING_STEP_TO_ROUTE, STEP_CHAT_PLACEHOLDER_TEXT, TOTAL_STEPS } from '../constants';
import { useChatInputStore } from '../stores/chatInputStore';
import { OnboardingStep } from '../types';

import { ChatInterface } from './chat/ChatInterface';
import { DesktopStepper } from './DesktopStepper';
import { MobileProgressBar } from './MobileProgressBar';
import classes from './OnboardingAgentContainer.module.css';
import { SettingsUIPanel } from './SettingsUIPanel';

interface OnboardingAgentContainerProps {
  initialStep?: OnboardingStep | null;
}

export const OnboardingAgentContainer: FC<OnboardingAgentContainerProps> = ({ initialStep }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);
  const [activeStep, setActiveStep] = useState<OnboardingStep>(initialStep ?? OnboardingStep.Calendars);
  const { submitForm } = useChatInputStore();

  const [showUIPane, setShowUIPane] = useState(true);

  // We don't want to show the UIPane for the video call
  const hasNoUIPane = activeStep === OnboardingStep.Video;

  // Update the URL when the active step changes
  useEffect(() => {
    const route = ONBOARDING_STEP_TO_ROUTE[activeStep];
    if (location.pathname !== route) {
      navigate(route);
    }
  }, [activeStep, location.pathname, navigate]);

  const nextStep = () =>
    setActiveStep((current) => {
      setShowUIPane(false);
      const nextStep = Math.min(current + 1, TOTAL_STEPS - 1) as OnboardingStep;
      submitForm(`I am ready to move on and set up my ${getStepLabel(nextStep)}`);
      return nextStep;
    });

  const prevStep = () =>
    setActiveStep((current) => {
      setShowUIPane(true);
      return Math.max(current - 1, 0) as OnboardingStep;
    });

  const handleStepClick = (step: number) => {
    setActiveStep(step as OnboardingStep);
    setShowUIPane(true);
  };

  const renderUIPane = showUIPane && !hasNoUIPane;
  const showChatInterface = isMobile ? !renderUIPane : true;
  const placeholderText = STEP_CHAT_PLACEHOLDER_TEXT[activeStep];

  return (
    <Box>
      {/* Desktop Stepper */}
      <Box maw={{ base: '100%', sm: '800px' }} mx="auto">
        {!isMobile && <DesktopStepper activeStep={activeStep} onStepClick={handleStepClick} />}
      </Box>
      <Box p={{ base: 'xs', sm: 'md' }}>
        {/* Mobile Progress Indicator */}
        {isMobile && (
          <MobileProgressBar
            activeStep={activeStep}
            prevStep={prevStep}
            showUIPane={renderUIPane}
            setShowUIPane={setShowUIPane}
          />
        )}

        {/* Main container with fixed height */}
        <Box h={{ base: 'calc(100vh - 90px)', sm: 'calc(100vh - 150px)' }} pos="relative">
          {/* Left panel with framer motion animations */}
          <AnimatePresence>
            {renderUIPane && (
              <motion.div
                initial={{ opacity: 0, x: isMobile ? 0 : -20, y: isMobile ? -20 : 0 }}
                animate={{ opacity: 1, x: 0, y: 0 }}
                exit={{ opacity: 0, x: isMobile ? 0 : -20, y: isMobile ? -20 : 0 }}
                transition={{ duration: 0.4, ease: 'easeInOut' }}
                className={isMobile ? classes.mobilePanel : classes.leftPanel}
              >
                <Paper shadow="sm" radius="md" h="100%">
                  <SettingsUIPanel step={activeStep} nextStep={nextStep} />
                </Paper>
              </motion.div>
            )}
          </AnimatePresence>

          {/* Chat Interface with framer motion animations */}
          <AnimatePresence>
            <Box display={showChatInterface ? 'block' : 'none'}>
              <motion.div
                initial={{
                  opacity: isMobile ? 0 : 1,
                  width: isMobile ? '100%' : '33.33%',
                  right: 0,
                  y: isMobile ? 20 : 0,
                }}
                animate={{
                  opacity: 1,
                  width: isMobile ? '100%' : '33.33%',
                  left: !isMobile && renderUIPane ? 'auto' : isMobile ? 0 : '33.33%',
                  y: 0,
                }}
                exit={isMobile ? { opacity: 0, y: 20 } : undefined}
                transition={{ duration: 0.4, ease: [0.25, 0.1, 0.25, 1] }}
                className={isMobile ? classes.mobileChatInterface : classes.chatInterface}
              >
                <Box h="100%">
                  <ChatInterface
                    setStep={setActiveStep}
                    setShowLeftPanel={setShowUIPane}
                    placeholderText={placeholderText}
                  />
                </Box>
              </motion.div>
            </Box>
          </AnimatePresence>
        </Box>
        {/* Floating Chat Button for Mobile */}
        {isMobile && renderUIPane && (
          <div className={classes.floatingChatButton}>
            <Tooltip label="Chat with Blockit" position="left" withArrow>
              <ActionIcon
                onClick={() => setShowUIPane(false)}
                variant="filled"
                color="blockitBlue"
                radius="xl"
                size="xl"
                className={classes.chatActionIcon}
              >
                <IconMessageCircle size={24} />
              </ActionIcon>
            </Tooltip>
          </div>
        )}
      </Box>
    </Box>
  );
};
