import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';

import { axios } from '@lib/axios';

interface UpdateEventStateParams {
  meetingId: string;
  eventState: string;
}

export const updateEventState = async (params: UpdateEventStateParams) => {
  const { meetingId, eventState } = params;
  await axios.patch(`/meeting/${meetingId}/event-state`, {
    eventState,
  });
  return true;
};

export const useUpdateEventState = (options?: UseMutationOptions<boolean, unknown, UpdateEventStateParams>) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateEventState,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({ queryKey: ['meetings', variables.meetingId] });
      options?.onSuccess?.(data, variables, context);
    },
    ...options,
  });
};
