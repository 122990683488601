import { FC } from 'react';

import { Modal } from '@mantine/core';

import { DayOfWeek, GooglePlaceInfo } from '@/types';

import { useUpsertPointOfInterest } from '../api/upsertPointOfInterest';
import { LocationType, PointOfInterest } from '../types';

import { PointOfInterestForm } from './PointOfInterestForm';

interface PointOfInterestModalProps {
  isOpen: boolean;
  onClose: () => void;
  initialPointOfInterest: PointOfInterest | null;
}

export const PointOfInterestModal: FC<PointOfInterestModalProps> = ({ isOpen, onClose, initialPointOfInterest }) => {
  const { mutate: upsertPointOfInterest } = useUpsertPointOfInterest();

  const handleSubmit = (values: {
    location: GooglePlaceInfo | null;
    nickname: string;
    locationType: LocationType;
    anchorAvailability: DayOfWeek[];
    instructions: string;
  }) => {
    const { location, nickname, ...otherValues } = values;
    upsertPointOfInterest({
      ...otherValues,
      name: nickname,
      googlePlaceId: location?.placeId || '',
      locationDescription: location?.description || '',
      id: initialPointOfInterest?.id,
    });

    onClose();
  };
  return (
    <Modal w="100%" opened={isOpen} onClose={onClose} title={initialPointOfInterest ? 'Edit Location' : 'Add Location'}>
      <PointOfInterestForm onSubmit={handleSubmit} initialPointOfInterest={initialPointOfInterest} />
    </Modal>
  );
};
