import { DateTime } from 'luxon';
import { v4 as uuidv4 } from 'uuid';

import { DayOfWeek } from '@/types';

import { AvaibilityEvent, convertDateInputToDateTime } from './fullCalendar';

type CopyAvailabilityEventParams = {
  sourceEvent: AvaibilityEvent;
  targetDays: DayOfWeek[];
  currentWeek?: DateTime;
};

/**
 * Copies an availability event to multiple days of the week.
 * This utility is specifically designed for copying availability blocks across different days.
 *
 * @param params.sourceEvent - The original event to copy
 * @param params.targetDays - Array of days of week to copy the event to
 * @param params.currentWeek - The current week's DateTime (defaults to DateTime.now())
 * @returns Array of new AvaibilityEvents with the correct dates and properties
 */
export const copyAvailabilityEventToDays = ({
  sourceEvent,
  targetDays,
  currentWeek = DateTime.now(),
}: CopyAvailabilityEventParams): AvaibilityEvent[] => {
  // Get the source event's start and end times in HH:mm format
  const currentStartTime = sourceEvent.start
    ? convertDateInputToDateTime(sourceEvent.start).toFormat('HH:mm')
    : undefined;
  const currentEndTime = sourceEvent.end ? convertDateInputToDateTime(sourceEvent.end).toFormat('HH:mm') : undefined;

  if (!currentStartTime || !currentEndTime) {
    return [];
  }

  // Parse the hours and minutes from the time strings
  const [startHour, startMinute] = currentStartTime.split(':');
  const [endHour, endMinute] = currentEndTime.split(':');

  // Create events for each target day
  return targetDays.map((targetDay) => {
    // Create the target date based on the week and target day
    const targetDate = currentWeek.startOf('week').plus({ days: parseInt(targetDay, 10) - 1 });

    // Set the hours and minutes on the target date
    const startDate = targetDate
      .set({
        hour: parseInt(startHour),
        minute: parseInt(startMinute),
      })
      .toJSDate();

    const endDate = targetDate
      .set({
        hour: parseInt(endHour),
        minute: parseInt(endMinute),
      })
      .toJSDate();

    // Create and return the new event
    return {
      ...sourceEvent,
      start: startDate,
      end: endDate,
      id: uuidv4(),
      title: sourceEvent.title ? sourceEvent.title : undefined,
      extendedProps: {
        ...sourceEvent.extendedProps,
      },
    };
  });
};
