const SLACK_APP_CLIENT_ID = import.meta.env.VITE_SLACK_APP_CLIENT_ID || '5419298431573.6291637482614';
const BOT_SCOPE = [
  'app_mentions:read',
  'assistant:write',
  'chat:write',
  'commands',
  'files:read',
  'im:history',
  'users:read',
  'users:read.email',
  'channels:read',
  'groups:read',
].join(',');

export function ConnectSlackButton() {
  const handleConnect = () => {
    const authUrl = new URL('https://slack.com/oauth/v2/authorize');
    authUrl.searchParams.append('client_id', SLACK_APP_CLIENT_ID);
    authUrl.searchParams.append('scope', BOT_SCOPE);
    authUrl.searchParams.append('user_scope', '');

    window.location.href = authUrl.toString();
  };

  const slackButtonSrc = 'https://platform.slack-edge.com/img/add_to_slack.png';
  const slackButtonSrcSet = `${slackButtonSrc} 1x, ${slackButtonSrc.replace('.png', '@2x.png')} 2x`;

  return (
    <a href="#" onClick={handleConnect} style={{ width: 'fit-content' }}>
      <img alt="Add to Slack" height="40" width="139" src={slackButtonSrc} srcSet={slackButtonSrcSet} />
    </a>
  );
}
