import { FC } from 'react';

import { Box } from '@mantine/core';

import { PlacesContainer } from '@features/location';
import { usePointsOfInterest } from '@features/location/api/getPointsOfInterest';

export const PlacesStep: FC = () => {
  const { data: pointsOfInterest } = usePointsOfInterest();

  if (!pointsOfInterest) {
    return null;
  }

  return (
    <Box>
      <PlacesContainer hideLocationControls={true} />
    </Box>
  );
};
