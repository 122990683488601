import { useEffect, useState } from 'react';

import { Container, Loader } from '@mantine/core';
import { Navigate } from 'react-router-dom';

import { PageContainer } from '@components/PageContainer';
import { useLogPageView } from '@features/analytics';
import { useCalendarsByAccounts } from '@features/calendar-selection/api/getCalendars';
import { useUser } from '@features/users';
import { PRIVATE_ROUTES } from '@routes/enums';

import { LoginCard } from './LoginCard';

export const LoginPage = () => {
  const { data: user, isLoading: userIsLoading } = useUser();
  const { data: accounts, isLoading: accountsAreLoading } = useCalendarsByAccounts();
  const [isNewUser, setIsNewUser] = useState<boolean | null>(null);

  useLogPageView('Login');

  useEffect(() => {
    if (isNewUser === null && accounts) {
      const hasAccounts = accounts && accounts.length > 0;
      setIsNewUser(!hasAccounts);
    }
  }, [accounts, isNewUser]);

  if (userIsLoading || accountsAreLoading)
    return (
      <PageContainer>
        <Loader size={30} />
      </PageContainer>
    );

  const userCanLogin = user && !user.suspendedAt;

  // We don't want to show the add calendars card if the user has already added calendars
  if (isNewUser === false && userCanLogin) {
    return <Navigate to={PRIVATE_ROUTES.INTEGRATIONS} />;
  }
  if (user && isNewUser) {
    return <Navigate to={PRIVATE_ROUTES.ONBOARDING} />;
  }

  return (
    <PageContainer>
      {!userCanLogin && (
        <Container maw={450}>
          <LoginCard />
        </Container>
      )}
    </PageContainer>
  );
};
