import { FC, ReactNode, useState } from 'react';

import { Box, Center } from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';
import { AnimatePresence, motion } from 'framer-motion';

import { FloatingButton } from '@/components/FloatingButton';
import { FloatingContentContainer } from '@components/FloatingContentContainer';
import { PointOfInterestModal } from '@features/location';

import { OnboardingStep } from '../types';

import classes from './SettingsUIPanel.module.css';
import { AvailabilityStep } from './steps/AvailabilityStep';
import { ConnectCalendarStep } from './steps/ConnectCalendarStep';
import { MeetingPreferencesStep } from './steps/MeetingPreferencesStep';
import { PlacesStep } from './steps/PlacesStep';

interface SettingsPanelProps {
  step: OnboardingStep;
  nextStep: () => void;
}

interface AnimatedStepProps {
  children: ReactNode;
  stepKey: string;
}

const AnimatedStep: FC<AnimatedStepProps> = ({ children, stepKey }) => (
  <motion.div
    key={stepKey}
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    transition={{ duration: 0.5, ease: 'easeInOut' }}
    className={classes.animatedStep}
  >
    {children}
  </motion.div>
);

export const SettingsUIPanel: FC<SettingsPanelProps> = ({ step, nextStep }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <Box h="100%" style={{ position: 'relative' }}>
      <Box p="xl" style={{ height: '100%', overflowY: 'auto', paddingBottom: '80px' }}>
        <AnimatePresence mode="wait">
          {step === OnboardingStep.Calendars && (
            <Center h="100%">
              <AnimatedStep stepKey="calendars">
                <ConnectCalendarStep />
              </AnimatedStep>
            </Center>
          )}
          {step === OnboardingStep.Availability && (
            <AnimatedStep stepKey="availability">
              <AvailabilityStep />
            </AnimatedStep>
          )}
          {step === OnboardingStep.Places && (
            <AnimatedStep stepKey="places">
              <Box>
                <PlacesStep />
                <PointOfInterestModal
                  isOpen={isModalOpen}
                  onClose={() => setIsModalOpen(false)}
                  initialPointOfInterest={null}
                />
              </Box>
            </AnimatedStep>
          )}
          {step === OnboardingStep.MeetingPreferences && (
            <AnimatedStep stepKey="meeting-preferences">
              <MeetingPreferencesStep />
            </AnimatedStep>
          )}
        </AnimatePresence>
      </Box>

      <FloatingContentContainer>
        {step === OnboardingStep.Places && (
          <>
            <FloatingButton onClick={() => setIsModalOpen(true)} variant="outline" bg="white">
              <IconPlus size={16} />
              Add location
            </FloatingButton>
          </>
        )}
        <FloatingButton onClick={nextStep}>Continue</FloatingButton>
      </FloatingContentContainer>
    </Box>
  );
};
