import { Button, Chip, Group, Select, Stack, Text, Textarea, TextInput, Tooltip } from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconAsterisk, IconInfoCircle } from '@tabler/icons-react';

import { DayOfWeek, GooglePlaceInfo } from '@/types';
import { LocationAutocomplete } from '@components/Form/LocationAutocomplete';
import { DAY_OF_WEEK_LABELS_ABBREVIATED } from '@constants/index';

import { usePointsOfInterest } from '../api/getPointsOfInterest';
import { LocationType, PointOfInterest } from '../types';

const ANCHOR_LOCATIONS = [LocationType.OFFICE, LocationType.HOME];

const LOCATION_TYPES = [
  { value: LocationType.HOME, label: 'Home' },
  { value: LocationType.OFFICE, label: 'Office' },
  { value: LocationType.COFFEE, label: 'Coffee' },
  { value: LocationType.BREAKFAST, label: 'Breakfast' },
  { value: LocationType.BRUNCH, label: 'Brunch' },
  { value: LocationType.LUNCH, label: 'Lunch' },
  { value: LocationType.DINNER, label: 'Dinner' },
  { value: LocationType.DRINKS, label: 'Drinks' },
];

const styles = {
  textSize: 'sm',
  textWeight: 500,
  titleNegativeMargin: -14,
  asteriskSize: 7,
  asteriskColor: 'red',
  asteriskMargin: { marginBottom: 7 },
};

interface PointOfInterestFormProps {
  initialPointOfInterest: PointOfInterest | null;
  onSubmit: (values: {
    location: GooglePlaceInfo | null;
    nickname: string;
    locationType: LocationType;
    anchorAvailability: DayOfWeek[];
    instructions: string;
    neighborhoodOverride: string;
  }) => void;
}

export const PointOfInterestForm = ({ initialPointOfInterest, onSubmit }: PointOfInterestFormProps) => {
  const { data: pointsOfInterest } = usePointsOfInterest();
  const form = useForm({
    initialValues: {
      location: initialPointOfInterest?.location.googlePlaceId
        ? {
            placeId: initialPointOfInterest?.location.googlePlaceId,
            description: initialPointOfInterest?.location.description,
          }
        : null,
      nickname: initialPointOfInterest?.name ?? '',
      locationType: initialPointOfInterest?.locationType ?? LocationType.HOME,
      anchorAvailability: initialPointOfInterest?.anchorAvailability ?? [],
      instructions: initialPointOfInterest?.instructions ?? '',
      neighborhoodOverride: initialPointOfInterest?.neighborhoodOverride ?? '',
    },
    validate: {
      location: (value) => (value ? null : 'Address is required'),
      nickname: (value) => (value ? null : 'Nickname is required'),
      locationType: (value) => (value ? null : 'Location type is required'),
    },
  });

  if (!pointsOfInterest) {
    return null;
  }
  const existingAnchorAvailabilities = pointsOfInterest
    .filter((pointOfInterest) => pointOfInterest.id !== initialPointOfInterest?.id)
    .flatMap((pointOfInterest) => pointOfInterest.anchorAvailability);

  return (
    <form onSubmit={form.onSubmit((values) => onSubmit(values))}>
      <Stack>
        <Select
          label="Type"
          required
          allowDeselect={false}
          data={LOCATION_TYPES}
          {...form.getInputProps('locationType')}
          onChange={(value) => {
            if (!ANCHOR_LOCATIONS.includes(value as LocationType)) {
              form.setFieldValue('anchorAvailability', []);
            }
            form.setFieldValue('locationType', value as LocationType);
          }}
        />
        <Group gap={4} mb={styles.titleNegativeMargin}>
          <Text size={styles.textSize} fw={styles.textWeight}>
            Street Address
          </Text>
          <IconAsterisk size={styles.asteriskSize} color={styles.asteriskColor} style={styles.asteriskMargin} />
          <Tooltip
            // eslint-disable-next-line max-len
            label='Only enter the street or establishment address here. Use "Custom Instructions" below for floor and suite details.'
            position="top"
            withArrow
            multiline
            w={350}
          >
            <IconInfoCircle size={14} style={{ cursor: 'help' }} />
          </Tooltip>
        </Group>
        <LocationAutocomplete
          initialValue={form.values.location?.description || ''}
          setLocation={(location) => form.setFieldValue('location', location)}
          required
          {...form.getInputProps('location')}
        />
        <Group gap={4} mb={styles.titleNegativeMargin}>
          <Text size={styles.textSize} fw={styles.textWeight}>
            Location Name
          </Text>
          <IconAsterisk size={styles.asteriskSize} color={styles.asteriskColor} style={styles.asteriskMargin} />
          <Tooltip label="How you will personally refer to this location." position="top" withArrow>
            <IconInfoCircle size={14} style={{ cursor: 'help' }} />
          </Tooltip>
        </Group>
        <TextInput {...form.getInputProps('nickname')} required />
        <Group gap={4} mb={styles.titleNegativeMargin}>
          <Text size={styles.textSize} fw={styles.textWeight}>
            Custom Neighborhood Name
          </Text>
          <Tooltip
            // eslint-disable-next-line max-len
            label='Blockit will ask: "Would [Custom Neighborhood Name] be a good option?" Leave blank to use default from Google Maps.'
            position="top"
            withArrow
            multiline
            w={350}
          >
            <IconInfoCircle size={14} style={{ cursor: 'help' }} />
          </Tooltip>
        </Group>
        <TextInput
          placeholder={initialPointOfInterest?.neighborhoodOverride || 'e.g. Union Square'}
          {...form.getInputProps('neighborhoodOverride')}
        />
        <Group gap={4} mb={styles.titleNegativeMargin}>
          <Text size={styles.textSize} fw={styles.textWeight}>
            Custom Instructions
          </Text>
          <Tooltip
            // eslint-disable-next-line max-len
            label="Details such as floor number, parking instructions, or access codes which will be listed in the description of the calendar invite."
            position="top"
            withArrow
            multiline
            w={350}
          >
            <IconInfoCircle size={14} style={{ cursor: 'help' }} />
          </Tooltip>
        </Group>
        <Textarea
          placeholder="e.g. Our office is located on the 5th floor. Please ring 415-123-4567 when you arrive."
          {...form.getInputProps('instructions')}
        />
        {ANCHOR_LOCATIONS.includes(form.values.locationType) && (
          <>
            <Stack gap="xs">
              <Text size="sm" fw={500}>
                Schedule
              </Text>
              <Text size="xs" c="dimmed">
                Indicate the days you are based in this location.
              </Text>
            </Stack>
            <Chip.Group {...form.getInputProps('anchorAvailability')} multiple>
              <Group gap="xs">
                {Object.entries(DAY_OF_WEEK_LABELS_ABBREVIATED).map(([key, label]) => (
                  <Chip
                    key={key}
                    value={key}
                    size="xs"
                    disabled={existingAnchorAvailabilities.some((availability) => availability === key)}
                  >
                    {label}
                  </Chip>
                ))}
              </Group>
            </Chip.Group>
          </>
        )}
        <Button type="submit">Save Location</Button>
      </Stack>
    </form>
  );
};
