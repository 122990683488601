import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { axios, RequestError } from '@lib/axios';

import { Message } from '../types';

import { API_BASE_URL, QUERY_KEY } from './constants';

const getMessages = async (): Promise<Message[]> => {
  const { data } = await axios.get(`${API_BASE_URL}/messages`);
  return data.messages;
};

export const useMessages = (options?: UseQueryOptions<Message[], RequestError>) => {
  return useQuery<Message[], RequestError>({
    queryKey: [QUERY_KEY],
    queryFn: getMessages,
    ...options,
  });
};
