import { Stack } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconClockHour10 } from '@tabler/icons-react';

import { AnalyticsEventName, track } from '@features/analytics';

import { useUpdatePreferences } from '../api/updatePreferences';
import { MeetingFormat, PreferenceSettings } from '../types';

import { MinutesSelection } from './MinutesSelection';
import { SettingsGrid } from './SettingsGrid';

const MEETING_MINUTES_STEP = 5;

export const MeetingDuration = ({ initialPreferences, label }: { initialPreferences: PreferenceSettings, label: MeetingFormat }) => {
  const { mutate: updatePreferences } = useUpdatePreferences({
    onSuccess: () => {
      track({
        type: AnalyticsEventName.PreferencesUpdated,
        data: { preference: 'duration', isDefault: initialPreferences.isDefault },
      });
      notifications.show({ message: `${label} meeting duration updated`, color: 'green' });
    },
  });

  const handleDurationSelect = (value: number) => {
    if (label === MeetingFormat.InPerson) {
      updatePreferences({ ...initialPreferences, inPersonDuration: value });
    } else {
      updatePreferences({ ...initialPreferences, duration: value });
    }
  };

  return (
    <SettingsGrid
      Icon={IconClockHour10}
      title="Default Meeting Duration"
      description="Unless specified otherwise, Blockit will schedule your meetings for this duration."
      left={
        <Stack>
          <MinutesSelection
            label={label}
            initialValue={label === MeetingFormat.InPerson ? initialPreferences.inPersonDuration : initialPreferences.duration}
            onSelect={handleDurationSelect}
            step={MEETING_MINUTES_STEP}
          />
        </Stack>
      }
    />
  );
};
