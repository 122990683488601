import { Table, Text, TextInput } from '@mantine/core';

import { WithCopyButton } from '@components/Form/WithCopyButton';

import { TriggerPhraseExample } from './TriggerPhraseExample';

const SHARE_PHRASE = 'share my availability';
const APPROVAL_PHRASE = 'kindly coordinate';

export const TriggerPhraseForm = () => {
  return (
    <Table verticalSpacing="lg" layout="fixed" w="100%">
      <Table.Thead>
        <Table.Tr>
          <Table.Th>
            <Text fw={600} size="md">
              Action
            </Text>
          </Table.Th>
          <Table.Th>
            <Text fw={600} size="md">
              Codeword Phrase
            </Text>
          </Table.Th>
          <Table.Th>
            <Text fw={600} size="md">
              Example
            </Text>
          </Table.Th>
        </Table.Tr>
      </Table.Thead>

      <Table.Tbody>
        <Table.Tr>
          <Table.Td>
            <Text fw={500} mb={4}>
              Propose Everything
            </Text>
            <Text size="sm" c="dimmed">
              Blockit will propose every open slot on your calendar within a given window.
            </Text>
          </Table.Td>
          <Table.Td>
            <WithCopyButton value={SHARE_PHRASE} copyId="share">
              <TextInput value={SHARE_PHRASE} readOnly variant="filled" />
            </WithCopyButton>
          </Table.Td>
          <Table.Td>
            <TriggerPhraseExample phrase={SHARE_PHRASE} prefix="please" suffix="for next week" />
          </Table.Td>
        </Table.Tr>

        <Table.Tr>
          <Table.Td>
            <Text fw={500} mb={4}>
              Approval Mode
            </Text>
            <Text size="sm" c="dimmed" maw={300}>
              Blockit will share a draft and ask you for approval before taking action.
            </Text>
          </Table.Td>
          <Table.Td>
            <WithCopyButton value={APPROVAL_PHRASE} copyId="approval">
              <TextInput value={APPROVAL_PHRASE} readOnly variant="filled" />
            </WithCopyButton>
          </Table.Td>
          <Table.Td>
            <TriggerPhraseExample phrase={APPROVAL_PHRASE} suffix="a meeting for next week" />
          </Table.Td>
        </Table.Tr>
      </Table.Tbody>
    </Table>
  );
};
