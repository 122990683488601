import { FC } from 'react';

import { Box, Text } from '@mantine/core';
import { motion } from 'framer-motion';

import { LoadingDots } from './LoadingDots';

interface LoadingMessageProps {
  message: string;
}

export const LoadingMessage: FC<LoadingMessageProps> = ({ message }) => {
  return (
    <Box mt={4}>
      <Text
        size="sm"
        c="dimmed"
        component={motion.div}
        animate={{
          opacity: [0.5, 1],
        }}
        transition={{
          duration: 1.4,
          repeat: Infinity,
          repeatType: 'reverse',
        }}
      >
        {message}
        <LoadingDots />
      </Text>
    </Box>
  );
};
