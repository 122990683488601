import { FC, ReactNode } from 'react';

import cx from 'clsx';

import classes from './FloatingContentContainer.module.css';

interface FloatingContentStyleProps {
  bottom?: string | number;
  top?: string | number;
  zIndex?: number;
  align?: 'left' | 'center' | 'right';
}

interface FloatingContentContainerProps {
  children: ReactNode;
  className?: string;
  position?: FloatingContentStyleProps;
}

export const FloatingContentContainer: FC<FloatingContentContainerProps> = ({ children, className, position }) => {
  const containerStyle = {
    bottom: position?.bottom ?? '24px',
    top: position?.top,
    zIndex: position?.zIndex ?? 10,
    justifyContent: position?.align ?? 'center',
  };

  return (
    <div className={cx(classes.floatingContainer, className)} style={containerStyle}>
      {children}
    </div>
  );
};
