import { useQuery } from '@tanstack/react-query';

import { useFirebaseAuthState } from '@features/auth';
import { axios } from '@lib/axios';

import { User } from '../types';

const getUser = async (): Promise<User> => {
  const { data } = await axios.get('/user/fetch', { params: { silent: true } });
  return data.user;
};

export const useUser = () => {
  const { user: firebaseUser } = useFirebaseAuthState();

  return useQuery({
    queryKey: ['user'],
    queryFn: () => {
      if (!firebaseUser?.uid) {
        return null;
      }
      return getUser();
    },
    enabled: !!firebaseUser?.uid,
  });
};
