import React from 'react';

import { Select, SelectProps } from '@mantine/core';

import { TimeOfDay } from '@/types';
import { HOURS_IN_DAY, MINUTES_IN_HOUR } from '@constants/index';

interface RangeHours {
  start: number;
  end: number;
}

interface TimeInputSelectProps extends Omit<SelectProps, 'data' | 'onChange'> {
  value: TimeOfDay;
  onChange: (value: TimeOfDay) => void;
  incrementInMinutes?: number;
  rangeHours?: RangeHours;
}

const TIME_INCREMENT_IN_MINUTES = 15;

const generateTimeOptions = (
  incrementInMinutes: number = TIME_INCREMENT_IN_MINUTES,
  rangeHours: RangeHours = {
    start: 0,
    end: HOURS_IN_DAY,
  },
): { value: string; label: string }[] => {
  const options = [];

  for (let hour = rangeHours.start; hour < rangeHours.end; hour++) {
    for (let minute = 0; minute < MINUTES_IN_HOUR; minute += incrementInMinutes) {
      const value = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
      const displayHour = hour % 12 || 12;
      const amPm = hour < 12 ? 'AM' : 'PM';
      const label = `${displayHour}:${minute.toString().padStart(2, '0')} ${amPm}`;
      options.push({ value, label });
    }
  }
  return options;
};

export const TimeInputSelect: React.FC<TimeInputSelectProps> = ({
  value,
  onChange,
  incrementInMinutes,
  rangeHours,
  ...props
}) => {
  const timeOptions = React.useMemo(
    () => generateTimeOptions(incrementInMinutes, rangeHours),
    [incrementInMinutes, rangeHours],
  );

  const handleChange: SelectProps['onChange'] = (newValue) => {
    if (newValue) {
      onChange(newValue as TimeOfDay);
    }
  };

  return (
    <Select data={timeOptions} value={value} onChange={handleChange} searchable allowDeselect={false} {...props} />
  );
};
