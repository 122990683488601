import { FC } from 'react';

import { Box } from '@mantine/core';

import { useUpdatePreferences } from '@features/preferences';
import { usePreferences } from '@features/preferences/api/getPreferences';
import { AvailabilityCalendar } from '@features/preferences/components/AvailabilityCalendar';
import { Availability } from '@features/preferences/types';

export const AvailabilityStep: FC = () => {
  const { data: preferences } = usePreferences();
  const { mutate: updatePreferences, isPending: isPreferencesUpdating } = useUpdatePreferences();

  if (!preferences) {
    return null;
  }

  const updateAvailability = (availability: Availability, callback?: () => void) => {
    updatePreferences({ ...preferences, availability }, { onSuccess: callback });
  };

  return (
    <Box>
      <AvailabilityCalendar
        initialAvailability={
          preferences.availability || {
            1: [],
            2: [],
            3: [],
            4: [],
            5: [],
            6: [],
            7: [],
          }
        }
        updateAvailability={updateAvailability}
        isUpdating={isPreferencesUpdating}
        preferences={preferences}
      />
    </Box>
  );
};
