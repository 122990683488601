import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Box, Text, TextProps } from '@mantine/core';
import { AnimatePresence, motion } from 'framer-motion';
import Markdown from 'react-markdown';

import { BlockitLogo } from './BlockitLogo';
import styles from './StreamedText.module.css';

const ICON_SIZE = 16;
const LOGO_ANIMATION_DURATION = 0.3; // in seconds

interface StreamedTextProps extends TextProps {
  text: string;
  delay?: number;
  paragraphPauseDelay?: number;
  onComplete?: () => void;
}

export const StreamedText: React.FC<StreamedTextProps> = ({
  text,
  delay = 20,
  paragraphPauseDelay = 500,
  onComplete,
  ...textProps
}) => {
  // Split the full text into paragraphs
  const allParagraphs = text.split('\n\n').filter((p) => p.trim() !== '');

  // State to track which paragraph we're currently animating
  const [currentParagraphIndex, setCurrentParagraphIndex] = useState(0);
  // State to track the current character index within the current paragraph
  const [currentCharIndex, setCurrentCharIndex] = useState(0);
  // State to store all paragraphs that have been fully animated
  const [completedParagraphs, setCompletedParagraphs] = useState<string[]>([]);
  // State to store the partially animated current paragraph
  const [currentAnimatingParagraph, setCurrentAnimatingParagraph] = useState<string>('');
  // State to track if we're in the pause between paragraphs
  const [isPausing, setIsPausing] = useState(false);
  // Flag to track if this is the initial render
  const [isInitialRender, setIsInitialRender] = useState(true);

  // Refs for tracking paragraph positions
  const paragraphRefs = useRef<(HTMLDivElement | null)[]>([]);
  // State for logo position
  const [logoPosition, setLogoPosition] = useState<{ top: number; left: number } | null>({
    top: 0,
    left: 0,
  });
  // Ref for the container
  const containerRef = useRef<HTMLDivElement>(null);

  // Update logo position based on current paragraph
  const updateLogoPosition = useCallback(() => {
    const currentIndex = Math.min(completedParagraphs.length, allParagraphs.length - 1);
    const currentRef = paragraphRefs.current[currentIndex];

    if (currentRef && containerRef.current) {
      const rect = currentRef.getBoundingClientRect();
      const containerRect = containerRef.current.getBoundingClientRect();

      setLogoPosition({
        top: rect.top - containerRect.top,
        left: 0,
      });
    }
  }, [completedParagraphs.length, allParagraphs.length]);

  // Initialize logo position
  useEffect(() => {
    requestAnimationFrame(() => {
      updateLogoPosition();
      // After initial position is set, mark initial render as complete
      setIsInitialRender(false);
    });
  }, [updateLogoPosition]);

  // Main animation logic
  useEffect(() => {
    // If we've animated all paragraphs, call onComplete
    if (currentParagraphIndex >= allParagraphs.length) {
      if (onComplete) {
        onComplete();
      }
      return;
    }

    // If we're in the pause state, wait before starting the next paragraph
    if (isPausing) {
      const pauseTimer = setTimeout(() => {
        setIsPausing(false);
        updateLogoPosition();
      }, paragraphPauseDelay);

      return () => clearTimeout(pauseTimer);
    }

    const currentFullParagraph = allParagraphs[currentParagraphIndex];

    // If we haven't finished animating the current paragraph
    if (currentCharIndex < currentFullParagraph.length) {
      const timer = setTimeout(() => {
        const nextChar = currentFullParagraph[currentCharIndex];
        setCurrentAnimatingParagraph((prev) => prev + nextChar);
        setCurrentCharIndex((prev) => prev + 1);
      }, delay);

      return () => clearTimeout(timer);
    } else {
      // We've finished animating the current paragraph
      // Add it to completed paragraphs and prepare for the next one
      if (currentAnimatingParagraph) {
        setCompletedParagraphs((prev) => [...prev, currentAnimatingParagraph]);
      }
      setCurrentAnimatingParagraph('');
      setCurrentCharIndex(0);

      // Set pausing state to true to create a delay before starting the next paragraph
      setIsPausing(true);

      // Move to the next paragraph
      setCurrentParagraphIndex((prev) => prev + 1);
    }
  }, [
    currentParagraphIndex,
    currentCharIndex,
    delay,
    paragraphPauseDelay,
    allParagraphs,
    onComplete,
    isPausing,
    currentAnimatingParagraph,
    updateLogoPosition,
  ]);

  return (
    <Box className={styles.container} component="div">
      <Text {...textProps} component="div">
        <Box ref={containerRef} style={{ position: 'relative' }}>
          {/* Completed paragraphs */}
          {completedParagraphs.map((paragraph, index) => (
            <Box key={index} ml="26" className="markdown-wrapper" ref={(el) => (paragraphRefs.current[index] = el)}>
              <Markdown>{paragraph}</Markdown>
            </Box>
          ))}

          {/* Current paragraph */}
          {(currentAnimatingParagraph || isPausing) && (
            <Box
              ref={(el) => (paragraphRefs.current[completedParagraphs.length] = el)}
              ml="26"
              className="markdown-wrapper"
            >
              <Markdown
                components={{
                  p: ({ children }) => <p className={styles.markdownElement}>{children}</p>,
                  ul: ({ children }) => <ul className={styles.markdownElement}>{children}</ul>,
                  ol: ({ children }) => <ol className={styles.markdownElement}>{children}</ol>,
                }}
              >
                {currentAnimatingParagraph}
              </Markdown>
            </Box>
          )}

          {/* Animated logo */}
          <AnimatePresence mode="wait">
            {logoPosition && (
              <motion.div
                key={`logo-${Math.min(currentParagraphIndex, allParagraphs.length - 1)}`}
                className={styles.logoContainer}
                initial={{ top: logoPosition.top, left: logoPosition.left }}
                animate={{ top: logoPosition.top, left: logoPosition.left }}
                transition={{
                  ease: 'easeIn',
                  duration: isInitialRender ? 0 : LOGO_ANIMATION_DURATION,
                }}
              >
                <BlockitLogo size={ICON_SIZE} />
              </motion.div>
            )}
          </AnimatePresence>
        </Box>
      </Text>
    </Box>
  );
};
