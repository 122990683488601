import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { DateTime } from 'luxon';

const GCAL_API_URL = 'https://www.googleapis.com/calendar/v3/calendars/primary/events';

export const OUTLOOK_API_URL = 'https://graph.microsoft.com/v1.0/me/calendar/calendarView';

export interface MicrosoftCalendarEvent {
  id: string;
  subject: string;
  isAllDay?: boolean;
  start: {
    dateTime: string;
    timeZone: string;
  };
  end: {
    dateTime: string;
    timeZone: string;
  };
  isCancelled: boolean;
  webLink: string;
  createdDateTime: string;
  lastModifiedDateTime: string;
  organizer: {
    emailAddress: {
      address: string;
      name: string;
    };
  };
  attendees?: Array<{
    emailAddress: {
      address: string;
      name: string;
    };
    status: {
      response: string;
      time: string;
    };
  }>;
  bodyPreview?: string;
}

// This schema is based on Google Schema
export interface CalendarEvent {
  id: string;
  summary: string;
  start: {
    date?: string;
    dateTime?: string;
    timeZone?: string;
  };
  end: {
    date?: string;
    dateTime?: string;
    timeZone?: string;
  };
  status: string;
  htmlLink: string;
  created: string;
  updated: string;
  creator: {
    email: string;
    self?: boolean;
  };
  organizer: {
    email: string;
    self?: boolean;
  };
  attendees?: Array<{
    email: string;
    responseStatus: string;
    self?: boolean;
  }>;
  description?: string;
}

interface GoogleCalendarAccessToken {
  accessToken: string;
}

interface MicrosoftCalendarAccessToken {
  accessToken: string;
}

const fetchCalendarEvents = async (request?: GoogleCalendarAccessToken): Promise<CalendarEvent[] | null> => {
  if (!request) {
    return null;
  }

  const today = DateTime.now();
  const startingMonday = today.minus({ weeks: 4 }).startOf('week'); // Go back to the Monday 4 weeks ago
  const endOfLastWeek = today.minus({ weeks: 1 }).endOf('week'); // Move to the last Sunday

  const queryParams = {
    timeMin: startingMonday.toISO(),
    timeMax: endOfLastWeek.toISO(),
    showDeleted: 'false',
    singleEvents: 'true',
    maxResults: '500',
    orderBy: 'startTime',
  };

  const url = `${GCAL_API_URL}?${new URLSearchParams(queryParams).toString()}`;

  const response = await axios.get(url, {
    headers: {
      authorization: `Bearer ${request.accessToken}`,
    },
  });

  if (response.status !== 200) {
    throw new Error('Failed to fetch calendar events');
  }

  const data = response.data;
  return data.items as CalendarEvent[];
};

const fetchMicrosoftCalendarEvents = async (
  request?: MicrosoftCalendarAccessToken,
): Promise<CalendarEvent[] | null> => {
  if (!request) {
    return null;
  }

  const today = DateTime.now();
  const startingMonday = today.minus({ weeks: 4 }).startOf('week');
  const endOfLastWeek = today.minus({ weeks: 1 }).endOf('week');

  const queryParams = {
    startDateTime: startingMonday.toISO(),
    endDateTime: endOfLastWeek.toISO(),
    $top: '500',
    $orderby: 'start/dateTime',
  };

  const url = `${OUTLOOK_API_URL}?${new URLSearchParams(queryParams).toString()}`;

  const response = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${request.accessToken}`,
    },
  });

  if (response.status !== 200) {
    throw new Error('Failed to fetch Microsoft calendar events');
  }

  const data = response.data;
  const msEvents = data.value as MicrosoftCalendarEvent[];
  return msEvents.map((event) => ({
    id: event.id,
    summary: event.subject,
    start: {
      date: event.isAllDay ? event.start.dateTime : undefined,
      dateTime: event.isAllDay ? undefined : event.start.dateTime,
      timeZone:
        event.start.timeZone === 'Pacific Standard Time' ? 'America/Los_Angeles' : event.start.timeZone ?? 'UTC',
    },
    end: {
      date: event.isAllDay ? event.end.dateTime : undefined,
      dateTime: event.isAllDay ? undefined : event.end.dateTime,
      timeZone: event.end.timeZone === 'Pacific Standard Time' ? 'America/Los_Angeles' : event.start.timeZone ?? 'UTC',
    },
    htmlLink: event.webLink,
    created: event.createdDateTime,
    updated: event.lastModifiedDateTime,
    creator: {
      email: event.organizer.emailAddress.address,
    },
    status: '',
    description: event.bodyPreview,
    organizer: {
      email: event.organizer.emailAddress.address,
    },
    attendees: event.attendees?.map((attendee) => ({
      email: attendee.emailAddress.address,
      responseStatus: attendee.status.response,
    })),
  }));
};

export const useFetchCalendarEvents = ({
  googleAccessToken,
  microsoftAccessToken,
  isPersonalCalendar,
}: {
  googleAccessToken?: GoogleCalendarAccessToken;
  microsoftAccessToken?: MicrosoftCalendarAccessToken;
  isPersonalCalendar?: boolean;
}) => {
  return useQuery({
    queryKey: ['calendarEventsForWaitlistForm', googleAccessToken, microsoftAccessToken],
    queryFn: () =>
      googleAccessToken
        ? fetchCalendarEvents(googleAccessToken)
        : microsoftAccessToken
          ? fetchMicrosoftCalendarEvents(microsoftAccessToken)
          : null,
    enabled: (!!googleAccessToken || !!microsoftAccessToken) && !isPersonalCalendar,
  });
};
