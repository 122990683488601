import { PRIVATE_ROUTES } from '@routes/enums';

export enum OnboardingRelativeRoutes {
  ROOT = '',
  INTRO = 'intro',
  CALENDARS = 'calendars',
  AVAILABILITY = 'availability',
  PLACES = 'places',
  VIDEO = 'video',
  MEETING_PREFERENCES = 'meeting-preferences',
}

export enum OnboardingRoutes {
  ROOT = PRIVATE_ROUTES.ONBOARDING,
  INTRO = `${PRIVATE_ROUTES.ONBOARDING}/${OnboardingRelativeRoutes.INTRO}`,
  CALENDARS = `${PRIVATE_ROUTES.ONBOARDING}/${OnboardingRelativeRoutes.CALENDARS}`,
  AVAILABILITY = `${PRIVATE_ROUTES.ONBOARDING}/${OnboardingRelativeRoutes.AVAILABILITY}`,
  PLACES = `${PRIVATE_ROUTES.ONBOARDING}/${OnboardingRelativeRoutes.PLACES}`,
  VIDEO = `${PRIVATE_ROUTES.ONBOARDING}/${OnboardingRelativeRoutes.VIDEO}`,
  MEETING_PREFERENCES = `${PRIVATE_ROUTES.ONBOARDING}/${OnboardingRelativeRoutes.MEETING_PREFERENCES}`,
}

export enum OnboardingStep {
  Calendars = 0,
  Availability = 1,
  Places = 2,
  Video = 3,
  MeetingPreferences = 4,
}

export interface IntermediaryResponse {
  updatingPreferences: OnboardingFieldNames[];
  message: string;
}

export interface CreateMessageResponse {
  message: Message;
  updatedPreferences: OnboardingFieldNames[];
  suggestedResponses: string[];
  completedSection?: OnboardingSectionName;
  currentQuestionFieldName?: OnboardingFieldNames;
  onboardingComplete: boolean;
}

export interface Message {
  messageLogId?: string;
  role: MessageRole;
  content: string;
}

export enum MessageRole {
  USER = 'user',
  ASSISTANT = 'assistant',
}

export enum OnboardingFieldNames {
  MEETING_HOURS = 'meeting_hours',
  PREFERRED_HOURS = 'preferred_hours',
  // locations agent
  OFFICE_LOCATION = 'office_location',
  HOME_LOCATION = 'home_location',
  WORK_FROM_HOME_DAYS = 'work_from_home_days',
  WORK_FROM_OFFICE_DAYS = 'work_from_office_days',
  COFFEE_LOCATION = 'coffee_location',

  UPCOMING_TRIPS = 'upcoming_trips',

  REMOTE_MEETING_PLATFORM = 'remote_meeting_platform',
  MEETING_LIMITS = 'meeting_limits',
  MEETING_BREAKS = 'meeting_breaks',
  MEETING_DURATIONS = 'meeting_durations',
  TRAVEL_TIME = 'travel_time',
  FLEXIBILITY = 'flexibility',
  CONNECT_ZOOM = 'connect_zoom',
  CUSTOM_REMOTE_MEETING_LINK = 'custom_remote_meeting_link',
}

export enum OnboardingSectionName {
  AVAILABILITY = 'availability',
  PLACES = 'places',
  VIDEO = 'video',
  MEETING_PREFERENCES = 'meeting_preferences',
  BUFFERS = 'buffers',
}
