import { FC } from 'react';

import { Button, ButtonProps, PolymorphicComponentProps } from '@mantine/core';
import cx from 'clsx';

import classes from './FloatingButton.module.css';


export const FloatingButton: FC<PolymorphicComponentProps<'button', ButtonProps>> = ({ children, className, ...props }) => {
  return (
    <Button size="sm" className={cx(classes.floatingButton, className)} {...props}>
      {children}
    </Button>
  );
};
