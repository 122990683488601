import { ActionIcon, Tooltip } from "@mantine/core";
import { IconPlayerPause, IconPlayerPlay } from "@tabler/icons-react";
import { useState } from "react";
import { EventState } from "../types";
import { notifications } from "@mantine/notifications";
import { useUpdateEventState } from "../api/updateEventState";
import { AnalyticsEventName, track } from "@features/analytics";

interface PauseSchedulingProps {
  initialPausedState: boolean;
  meetingId: string;
}

const notificationsMessage = (paused: boolean) => {
    return paused ? 
        'Meeting scheduling paused successfully and can now be found in the "Paused" tab'
        : 'Meeting scheduling resumed successfully and can now be found in the "Being Scheduled" tab';
}

export const PauseScheduling: React.FC<PauseSchedulingProps> = ({ initialPausedState, meetingId }) => {
    const [paused, setPaused] = useState(initialPausedState);

    const { mutate: updateEventState, isPending } = useUpdateEventState({
        onSuccess: () => {
            track({
                type: AnalyticsEventName.StopSchedulingInitiated,
                data: { meetingId, paused, context: 'web-app'},
            });
            notifications.show({
                title: 'Meeting scheduling '+ (paused ? 'paused' : 'resumed'),
                message: notificationsMessage(paused),
                color: 'green',
            });
        },
    });

    const handlePauseScheduling = () => {
        const newValue = !paused;
        setPaused(newValue);
        updateEventState({
            meetingId: meetingId,
            eventState: newValue ? EventState.PAUSED : EventState.SCHEDULING,
        });
    };

    return (
        <Tooltip label={paused ? 'Resume scheduling' : 'Pause scheduling'}>
            <ActionIcon variant="default" size="compact-sm" onClick={handlePauseScheduling} disabled={isPending}>
                {paused ? <IconPlayerPlay size={18} /> : <IconPlayerPause size={18} />}
            </ActionIcon>
        </Tooltip>
    );
};
