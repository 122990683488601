import { FC } from 'react';

import { Box, Flex, Text } from '@mantine/core';
import { IconCalendarPlus, IconClock, IconLocationPlus, IconVideo } from '@tabler/icons-react';

import { STEP_LABELS, TOTAL_STEPS } from '../constants';
import { OnboardingStep } from '../types';

import classes from './DesktopStepper.module.css';

interface DesktopStepperProps {
  activeStep: OnboardingStep;
  onStepClick: (step: number) => void;
}

export const DesktopStepper: FC<DesktopStepperProps> = ({ activeStep, onStepClick }) => {
  const stepIcons = [
    <IconCalendarPlus key={0} size={18} stroke={1.5} />,
    <IconClock key={1} size={18} stroke={1.5} />,
    <IconLocationPlus key={2} size={18} stroke={1.5} />,
    <IconVideo key={3} size={18} stroke={1.5} />,
  ];

  // This is quick fix to avoid showing the progress bar extending past the last step
  // We still need to figure out how we want the ui to be in this case
  const activeStepToShow = Math.min(activeStep, TOTAL_STEPS - 1);

  return (
    <Box className={classes.container} miw={stepIcons.length * 100}>
      <Flex direction="column" align="center">
        {/* Progress bar with circles */}
        <Box className={classes.progressContainer}>
          {/* Horizontal line */}
          <Box className={classes.horizontalLine} />

          {/* Completed line */}
          <Box
            className={classes.completedLine}
            style={{ width: `${(activeStepToShow / (TOTAL_STEPS - 1)) * 100}%` }}
          />

          {/* Step circles */}
          <Flex justify="space-between" align="center">
            {STEP_LABELS.map((_, index) => (
              <Box
                key={index}
                onClick={() => onStepClick(index)}
                className={`${classes.stepCircle} ${
                  index <= activeStepToShow
                    ? index === activeStepToShow
                      ? classes.stepCircleActive
                      : classes.stepCircleCompleted
                    : classes.stepCircleInactive
                }`}
              >
                {index + 1}
              </Box>
            ))}
          </Flex>
        </Box>

        {/* Labels */}
        <Flex justify="space-between" align="flex-start" className={classes.labelsContainer}>
          {STEP_LABELS.map((label, index) => (
            <Flex key={index} direction="column" align="center" className={classes.labelItem}>
              <Box className={classes.labelContent}>
                {stepIcons[index]}
                <Text fw={600} size="sm" c="black">
                  {label}
                </Text>
              </Box>
            </Flex>
          ))}
        </Flex>
      </Flex>
    </Box>
  );
};
