import { FC } from 'react';

import { Box, Button, Stack } from '@mantine/core';

import { ConnectZoomButton } from '@features/auth/components/ConnectZoomButton';
import { useChatInputStore } from '@features/onboarding/stores/chatInputStore';
import { OnboardingFieldNames } from '@features/onboarding/types';

import { CustomRemoteMeetingInstructions } from './CustomRemoteMeetingInstructions';

interface SpecializedInstructionsProps {
  currentQuestionFieldName: OnboardingFieldNames | null;
}

export const SpecializedInstructions: FC<SpecializedInstructionsProps> = ({ currentQuestionFieldName }) => {
  const { submitForm } = useChatInputStore();
  if (currentQuestionFieldName === OnboardingFieldNames.CONNECT_ZOOM) {
    return (
      <Stack gap="xs">
        <Box>
          <ConnectZoomButton size="sm" radius="md">
            Connect Zoom
          </ConnectZoomButton>
        </Box>
        <Box>
          <Button variant="subtle" size="xs" c="dimmed" onClick={() => submitForm(`I can't connect my Zoom account`)}>
            I can&apos;t connect my Zoom account
          </Button>
        </Box>
      </Stack>
    );
  }

  if (currentQuestionFieldName === OnboardingFieldNames.CUSTOM_REMOTE_MEETING_LINK) {
    return <CustomRemoteMeetingInstructions />;
  }

  return null;
};
