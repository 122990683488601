import { create } from 'zustand';

interface ChatInputStore {
  submitForm: (message: string) => void;
  setSubmitForm: (submitFn: (message: string) => void) => void;
}

export const useChatInputStore = create<ChatInputStore>((set) => ({
  submitForm: () => {}, // Default empty function
  setSubmitForm: (submitFn: (message: string) => void) => set({ submitForm: submitFn }),
}));
