import { FC, useCallback, useEffect, useState } from 'react';

import { ActionIcon, Anchor, Box, TextInput } from '@mantine/core';
import { IconCircleArrowUp } from '@tabler/icons-react';
import { useSearchParams } from 'react-router-dom';

import { GooglePlaceInfo } from '@/types';
import { LocationAutocomplete } from '@components/Form/LocationAutocomplete';
import { PulsatingBlockitLogo } from '@components/PulsatingBlockitLogo';
import { LOCATION_AUTOCOMPLETE_QUESTION_FIELD_NAMES } from '@features/onboarding/constants';
import { useChatInputStore } from '@features/onboarding/stores/chatInputStore';
import { OnboardingFieldNames } from '@features/onboarding/types';

import { SuggestedResponses } from './SuggestedResponses';

interface ChatInputProps {
  onSubmit: (message: string) => void;
  isResponding: boolean;
  suggestedResponses?: string[];
  currentQuestionFieldName: OnboardingFieldNames | null;
  placeholderText?: string;
  onboardingComplete: boolean;
}

export const ChatInput: FC<ChatInputProps> = ({
  onSubmit,
  isResponding,
  suggestedResponses,
  currentQuestionFieldName,
  placeholderText,
  onboardingComplete,
}) => {
  const [message, setMessage] = useState('');
  const [searchParams] = useSearchParams();
  const [useManualInput, setUseManualInput] = useState(false);
  const { setSubmitForm } = useChatInputStore();

  // Helper function to submit the form programmatically
  const populateFormAndSubmit = useCallback(
    (inputMessage: string) => {
      setMessage(inputMessage);
      setTimeout(() => {
        onSubmit(inputMessage);
        setUseManualInput(false);
        setMessage('');
      }, 300);
    },
    [onSubmit],
  );

  // Register the submit function with the store
  useEffect(() => {
    // Note this has the effect of only setting the submit form on the
    // store on the first render, this is ok because the function should
    // not change during the course of the onboarding flow, ideally we
    // would have exhaustive deps here but we are running into infinite
    // render issues, we may want to revisit this in the future
    setSubmitForm(populateFormAndSubmit);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Check for tellAgentZoomConnected parameter
  useEffect(() => {
    const tellAgentZoomConnected = searchParams.get('tellAgentZoomConnected');

    if (tellAgentZoomConnected === 'true') {
      populateFormAndSubmit('I connected my zoom!');
      searchParams.delete('tellAgentZoomConnected');
    }
  }, [searchParams, populateFormAndSubmit]);

  // Handle suggested response click
  const handleSuggestedResponseClick = (response: string) => {
    // Submit the form with the selected response
    populateFormAndSubmit(response);
  };

  // Handle location selection
  const handleLocationSelect = (location: GooglePlaceInfo | null) => {
    if (location) {
      populateFormAndSubmit(location.description);
    }
  };

  // Check if the current question requires location autocomplete
  const showLocationAutocomplete =
    currentQuestionFieldName &&
    LOCATION_AUTOCOMPLETE_QUESTION_FIELD_NAMES.includes(currentQuestionFieldName) &&
    !isResponding &&
    !useManualInput;

  const hasSpecializedInput = useManualInput || showLocationAutocomplete;
  const hasCustomInstructions =
    currentQuestionFieldName === OnboardingFieldNames.CUSTOM_REMOTE_MEETING_LINK ||
    currentQuestionFieldName === OnboardingFieldNames.CONNECT_ZOOM ||
    onboardingComplete;

  // Determine if we should show suggested responses
  const shouldShowSuggestedResponses =
    suggestedResponses &&
    suggestedResponses.length > 0 &&
    !hasSpecializedInput &&
    !hasCustomInstructions &&
    !isResponding;

  // Determine if we should show the text input
  const showTextInput = useManualInput || !showLocationAutocomplete;
  const showManualInputToggle = hasSpecializedInput;

  return (
    <Box w="100%" bg="transparent" px={{ base: 'xs', md: 'lg' }}>
      {shouldShowSuggestedResponses && (
        <SuggestedResponses
          responses={suggestedResponses!}
          onResponseClick={handleSuggestedResponseClick}
          isDisabled={isResponding}
        />
      )}
      <Box
        component="form"
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit(message);
          setUseManualInput(false);
          setMessage('');
        }}
        bg="transparent"
      >
        {showLocationAutocomplete && <LocationAutocomplete setLocation={handleLocationSelect} label="" />}

        {showTextInput && (
          <TextInput
            radius="md"
            size="sm"
            placeholder={placeholderText || 'Ask Blockit anything'}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            disabled={isResponding}
            rightSection={
              isResponding ? (
                <PulsatingBlockitLogo size={18} />
              ) : (
                <ActionIcon
                  variant="transparent"
                  color="blue"
                  onClick={(e) => {
                    e.preventDefault();
                    onSubmit(message);
                    setUseManualInput(false);
                    setMessage('');
                  }}
                  disabled={!message.trim()}
                >
                  <IconCircleArrowUp size={20} />
                </ActionIcon>
              )
            }
          />
        )}

        {showManualInputToggle && (
          <Anchor
            component="button"
            type="button"
            size="xs"
            onClick={() => setUseManualInput(!useManualInput)}
            c="dimmed"
            mt="xs"
          >
            {useManualInput ? 'Return to specialized input' : 'Enter text manually instead'}
          </Anchor>
        )}
      </Box>
    </Box>
  );
};
