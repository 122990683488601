import { FC } from 'react';

import { Box, Stack, Text, Title } from '@mantine/core';

import { SideContent } from '@components/SideContent';

export const BetaPage: FC = () => {
  return (
    <SideContent
      sideContentSize="300"
      sideContent={
        <Stack>
          <Box>
            <Title order={4}>Beta</Title>
            <Text c="dimmed" size="sm">
              Beta features.
            </Text>
          </Box>
        </Stack>
      }
    >
      <Stack>
        <Title>Beta Features</Title>
        <Text>No beta features are currently available.</Text>
      </Stack>
    </SideContent>
  );
};
//
