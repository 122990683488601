import React from 'react';

import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import { OnboardingPage } from '../components/OnboardingPage';
import { OnboardingRelativeRoutes, OnboardingRoutes, OnboardingStep } from '../types';

export const OnboardingRouter: React.FC = () => {
  const location = useLocation();
  const { search } = location;

  return (
    <Routes>
      <Route path="/" element={<Navigate to={`${OnboardingRoutes.INTRO}${search}`} replace />} />
      <Route path={OnboardingRelativeRoutes.INTRO} element={<OnboardingPage initialStep={null} />} />
      <Route
        path={OnboardingRelativeRoutes.CALENDARS}
        element={<OnboardingPage initialStep={OnboardingStep.Calendars} />}
      />
      <Route
        path={OnboardingRelativeRoutes.AVAILABILITY}
        element={<OnboardingPage initialStep={OnboardingStep.Availability} />}
      />
      <Route path={OnboardingRelativeRoutes.PLACES} element={<OnboardingPage initialStep={OnboardingStep.Places} />} />
      <Route path={OnboardingRelativeRoutes.VIDEO} element={<OnboardingPage initialStep={OnboardingStep.Video} />} />
      <Route
        path={OnboardingRelativeRoutes.MEETING_PREFERENCES}
        element={<OnboardingPage initialStep={OnboardingStep.MeetingPreferences} />}
      />
    </Routes>
  );
};
