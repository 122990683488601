import { Tabs } from '@mantine/core';

import { PreferenceSettings } from '../types';
import { RemotePreferences } from './RemotePreferences';
import { InPersonPreferences } from './InPersonPreferences';
import { GeneralPreferences } from './GeneralPreferences';

export const SettingsForm = ({ initialPreferences }: { initialPreferences: PreferenceSettings }) => {
  return (
    <Tabs defaultValue="general">
      <Tabs.List>
        <Tabs.Tab value="general">General</Tabs.Tab>
        <Tabs.Tab value="remote">Remote</Tabs.Tab>
        <Tabs.Tab value="in-person">In-Person</Tabs.Tab>
      </Tabs.List>

      <Tabs.Panel value="general" pt="xl">
        <GeneralPreferences initialPreferences={initialPreferences} />
      </Tabs.Panel>

      <Tabs.Panel value="remote" pt="xl">
        <RemotePreferences initialPreferences={initialPreferences} />
      </Tabs.Panel>

      <Tabs.Panel value="in-person" pt="xl">
        <InPersonPreferences initialPreferences={initialPreferences} />
      </Tabs.Panel>
    </Tabs>
  );
};
