import { FC, useState } from 'react';

import { Group, Stack, Text } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconBarrierBlock } from '@tabler/icons-react';

import { Switch } from '@components/Switch';
import { MINUTES_IN_HOUR } from '@constants/index';
import { AnalyticsEventName, track } from '@features/analytics';

import { useUpdatePreferences } from '../api/updatePreferences';
import { PreferenceSettings } from '../types';

import { MinutesSelection } from './MinutesSelection';
import { SettingsSectionTitle } from './SettingsSectionTitle';

const MAX_MEETING_MINUTES_RANGE: [number, number] = [MINUTES_IN_HOUR, MINUTES_IN_HOUR * 12];

const DEFAULT_MAX_MEETING_MINUTES = MINUTES_IN_HOUR * 6;

interface MaxMeetingMinutesProps {
  initialPreferences: PreferenceSettings;
}

export const MaxMeetingMinutes: FC<MaxMeetingMinutesProps> = ({ initialPreferences }) => {
  const { mutate: updatePreferences } = useUpdatePreferences({
    onSuccess: () => {
      track({
        type: AnalyticsEventName.PreferencesUpdated,
        data: { preference: 'maxMeetingMinutesPerDay', isDefault: initialPreferences.isDefault },
      });
      notifications.show({ message: 'Meeting limit updated', color: 'green' });
    },
  });

  const [isMaxMeetingMinutesEnabled, setIsMaxMeetingMinutesEnabled] = useState(
    Boolean(initialPreferences.maxMeetingMinutesPerDay),
  );

  const [maxMeetingMinutesPerDay, setMaxMeetingMinutesPerDay] = useState(
    initialPreferences.maxMeetingMinutesPerDay || DEFAULT_MAX_MEETING_MINUTES,
  );

  const handleMaxMeetingMinutesSelect = (value: number) => {
    setMaxMeetingMinutesPerDay(value);
    if (!isMaxMeetingMinutesEnabled) return;
    updatePreferences({
      ...initialPreferences,
      maxMeetingMinutesPerDay: value,
    });
  };

  const handleIsMaxMeetingMinutesEnabledChanged = () => {
    const newValue = !isMaxMeetingMinutesEnabled;
    setIsMaxMeetingMinutesEnabled(newValue);

    if (newValue) {
      updatePreferences({
        ...initialPreferences,
        maxMeetingMinutesPerDay,
      });
    } else {
      updatePreferences({
        ...initialPreferences,
        maxMeetingMinutesPerDay: undefined,
      });
    }
  };
  const textColor = isMaxMeetingMinutesEnabled ? undefined : 'gray.4';

  return (
    <Stack>
      <Group>
        <SettingsSectionTitle
          Icon={IconBarrierBlock}
          title="Meeting Limit"
          // eslint-disable-next-line max-len
          description="Set limits on the amount of time you spend in meetings each day. If no other availabilities can be found, Blockit may override these limits."
        />
        <Switch
          checked={isMaxMeetingMinutesEnabled}
          onChange={handleIsMaxMeetingMinutesEnabledChanged}
          offLabel="OFF"
          onLabel="ON"
        />
      </Group>

      <span>
        <Text span c={textColor}>
          I want to take no more than
        </Text>
        <MinutesSelection
          initialValue={maxMeetingMinutesPerDay}
          onSelect={handleMaxMeetingMinutesSelect}
          range={MAX_MEETING_MINUTES_RANGE}
          step={MINUTES_IN_HOUR}
          isInline
          disabled={!isMaxMeetingMinutesEnabled}
        />
        <Text span c={textColor}>
          of meetings a day.
        </Text>
      </span>
    </Stack>
  );
};
