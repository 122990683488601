import { useEffect } from 'react';

import { useMantineTheme } from '@mantine/core';
import {
  IconBowlSpoon,
  IconBread,
  IconBriefcaseFilled,
  IconBurger,
  IconCoffee,
  IconGlassCocktail,
  IconHomeFilled,
  IconToolsKitchen2,
} from '@tabler/icons-react';
import { AdvancedMarker, Pin, useMap } from '@vis.gl/react-google-maps';

import { LocationType, PointOfInterest } from '@features/location/types';

interface PointOfInterestMarkersProps {
  pointsOfInterest: PointOfInterest[];
}

const LocationTypeToIcon = {
  [LocationType.COFFEE]: IconCoffee,
  [LocationType.HOME]: IconHomeFilled,
  [LocationType.OFFICE]: IconBriefcaseFilled,
  [LocationType.BREAKFAST]: IconBowlSpoon,
  [LocationType.BRUNCH]: IconBread,
  [LocationType.LUNCH]: IconBurger,
  [LocationType.DINNER]: IconToolsKitchen2,
  [LocationType.DRINKS]: IconGlassCocktail,
};

const renderIcon = (type: LocationType) => {
  const Icon = LocationTypeToIcon[type];
  return <Icon size={16} color="white" />;
};

export const PointOfInterestMarkers = ({ pointsOfInterest }: PointOfInterestMarkersProps) => {
  const map = useMap();
  const theme = useMantineTheme();
  useEffect(() => {
    if (map && pointsOfInterest.length > 0) {
      const bounds = new google.maps.LatLngBounds();
      pointsOfInterest.forEach((point) => {
        bounds.extend({
          lat: point.location.latitude,
          lng: point.location.longitude,
        });
      });
      map.fitBounds(bounds);
    }
  }, [map, pointsOfInterest]);
  return (
    <>
      {pointsOfInterest.map((point) => (
        <AdvancedMarker
          key={point.id}
          position={{ lat: point.location.latitude, lng: point.location.longitude }}
          title={point.name}
        >
          <Pin background={theme.colors.blue[6]} borderColor={theme.colors.blue[6]}>
            {renderIcon(point.locationType)}
          </Pin>
        </AdvancedMarker>
      ))}
    </>
  );
};
