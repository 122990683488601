import { Divider, Grid, Stack, Text } from '@mantine/core';
import { IconBrandSlack, IconBrandZoom, IconCalendar } from '@tabler/icons-react';

import { SideContent } from '@components/SideContent';
import { TitleWithIcon } from '@components/TitleWithIcon';
import { CalendarSettingsContainer } from '@features/calendar-selection';

import { SlackIntegrationContainer } from './SlackIntegrationContainer';
import { ZoomIntegrationContainer } from './ZoomIntegrationContainer';

export const IntegrationsPage = () => {
  return (
    <SideContent sideContent={<Text c="dimmed">Connect your calendars and conferencing tools to Blockit.</Text>}>
      <Grid visibleFrom="xl">
        <Grid.Col span={5.5}>
          <Stack>
            <TitleWithIcon title="Calendars" icon={<IconCalendar size={32} />} order={2} />
            <CalendarSettingsContainer />
          </Stack>
        </Grid.Col>
        <Grid.Col span={1} />
        <Grid.Col span={5.5}>
          <Stack>
            <TitleWithIcon title="Zoom" icon={<IconBrandZoom size={32} />} order={2} />
            <ZoomIntegrationContainer />
            <Divider my="md" />
            <TitleWithIcon title="Slack" icon={<IconBrandSlack size={32} />} order={2} />
            <SlackIntegrationContainer />
          </Stack>
        </Grid.Col>
      </Grid>
      <Stack hiddenFrom="xl">
        <TitleWithIcon title="Calendars" icon={<IconCalendar size={32} />} order={2} />
        <CalendarSettingsContainer />
        <Divider />
        <TitleWithIcon title="Zoom" icon={<IconBrandZoom size={32} />} order={2} />
        <ZoomIntegrationContainer />
        <Divider />
        <TitleWithIcon title="Slack" icon={<IconBrandSlack size={32} />} order={2} />
        <SlackIntegrationContainer />
      </Stack>
    </SideContent>
  );
};
