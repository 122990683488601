import { Text } from '@mantine/core';

interface TriggerPhraseExampleProps {
  phrase: string;
  prefix?: string;
  suffix?: string;
}

export const TriggerPhraseExample = ({ phrase, prefix = '', suffix = '' }: TriggerPhraseExampleProps) => {
  return (
    <Text size="sm" fs="italic">
      &ldquo;Blockit, {prefix && ` ${prefix} `}
      <Text component="span" c="blue.5">
        {phrase}
      </Text>{' '}
      {suffix}&rdquo;
    </Text>
  );
};
