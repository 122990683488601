import { FC, Fragment, useEffect, useRef, useState } from 'react';

import { Box, Flex, ScrollArea, Stack } from '@mantine/core';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import { createSSEStream } from '@api/createSSEStream';
import { PulsatingBlockitLogo } from '@components/PulsatingBlockitLogo';

import { QUERY_KEY } from '../../api/constants';
import { useMessages } from '../../api/getMessages';
import {
  ONBOARDING_FIELD_NAMES_TO_STEP,
  ONBOARDING_SECTION_NAME_TO_STEP,
  ONBOARDING_STEP_TO_ROUTE,
} from '../../constants';
import {
  CreateMessageResponse,
  IntermediaryResponse,
  Message,
  MessageRole,
  OnboardingFieldNames,
  OnboardingStep,
} from '../../types';

import { AssistantMessage } from './AssistantMessage';
import { ChatInput } from './ChatInput';
import { LoadingMessage } from './LoadingMessage';
import { UserMessage } from './UserMessage';

interface ChatInterfaceProps {
  setStep: (step: OnboardingStep) => void;
  setShowLeftPanel: (show: boolean) => void;
  placeholderText?: string;
}

const PRESET_INITIAL_RESPONSES = [
  'How intelligent are you really, Blockit?',
  'Can I connect multiple calendars?',
  'How does group scheduling work?',
  'Do you account for travel time?',
  "I'd like to get started setting up my preferences",
];

export const ChatInterface: FC<ChatInterfaceProps> = ({ setStep, setShowLeftPanel, placeholderText }) => {
  const [loadingMessage, setLoadingMessage] = useState<string>('Thinking');
  const { data: messages, isLoading } = useMessages();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const [isResponding, setIsResponding] = useState(false);
  const [isThinking, setIsThinking] = useState(false);
  const [suggestedResponses, setSuggestedResponses] = useState<string[]>([]);
  const [currentQuestionFieldName, setCurrentQuestionFieldName] = useState<OnboardingFieldNames | null>(null);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const [onboardingComplete, setOnboardingComplete] = useState(false);
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleStepChange = (newStep: OnboardingStep) => {
    setStep(newStep);

    navigate(ONBOARDING_STEP_TO_ROUTE[newStep], { replace: true });
  };

  const submitMessage = async (message: string) => {
    if (!message.trim()) {
      return;
    }

    queryClient.setQueryData([QUERY_KEY], (oldData: Message[]) => {
      return [
        ...oldData,
        {
          content: message,
          role: MessageRole.USER,
          messageLogId: `msg-${oldData.length}`,
        },
      ];
    });

    setIsResponding(true);
    setIsThinking(true);

    createSSEStream<IntermediaryResponse, CreateMessageResponse>({
      url: '/onboarding/message/stream',
      messageBody: message,
      onMessage: (data) => {
        // We will update the loading message as we receive new intermediary messages
        // We will also refresh the preferences and points of interest as we receive new intermediary messages
        // since the agent may have updated them
        setLoadingMessage(data.message);
        if (data.updatingPreferences.length > 0) {
          const newStep = ONBOARDING_FIELD_NAMES_TO_STEP[data.updatingPreferences[0]];
          handleStepChange(newStep);
          queryClient.invalidateQueries({ queryKey: ['preferences'] });
          queryClient.invalidateQueries({ queryKey: ['pointsOfInterest'] });
        }
      },
      onComplete: (data) => {
        // Refetch the messages to get the latest ones
        queryClient.setQueryData([QUERY_KEY], (oldData: Message[]) => {
          return [...oldData, data.message];
        });
        setLoadingMessage('Thinking');
        // Set suggested responses from the API response
        setSuggestedResponses(data.suggestedResponses || []);

        setCurrentQuestionFieldName(data.currentQuestionFieldName || null);

        if (data.completedSection) {
          const newStep = ONBOARDING_SECTION_NAME_TO_STEP[data.completedSection];
          handleStepChange(newStep);
        } else if (data.currentQuestionFieldName) {
          const newStep = ONBOARDING_FIELD_NAMES_TO_STEP[data.currentQuestionFieldName];
          handleStepChange(newStep);
        }
        setShowLeftPanel(!!data.completedSection);
        setIsThinking(false);
        setOnboardingComplete(data.onboardingComplete);
      },
    });
    setSuggestedResponses([]);
  };

  if (isLoading || !messages) {
    return null;
  }

  return (
    <Stack h="100%" gap={0} w="100%">
      <Flex style={{ flex: 1, position: 'relative', overflow: 'hidden', zIndex: 100 }} w="100%">
        <ScrollArea h="100%" type="scroll" offsetScrollbars w="100%">
          <Stack pb={100} gap="md" px={{ base: 'xs', md: 'md' }} w="100%">
            {messages.map((message, index) => {
              const messageId = message.messageLogId || `msg-${index}`;
              return (
                <Fragment key={messageId}>
                  {message.role === MessageRole.ASSISTANT && (
                    <AssistantMessage
                      content={message.content}
                      isLastMessage={index === messages.length - 1}
                      currentQuestionFieldName={currentQuestionFieldName}
                      streamingCallback={() => {
                        setIsResponding(false);
                      }}
                      isResponding={isResponding}
                      onboardingComplete={onboardingComplete}
                    />
                  )}
                  {message.role === MessageRole.USER && <UserMessage content={message.content} />}
                </Fragment>
              );
            })}

            {isThinking && (
              <Flex align="flex-start" gap="xs">
                <PulsatingBlockitLogo size={24} />
                <LoadingMessage message={loadingMessage} />
              </Flex>
            )}

            <div ref={messagesEndRef} />
          </Stack>
        </ScrollArea>
      </Flex>

      <Box pr={{ base: 'xs', md: 'md' }} pl={{ base: 'xs', md: 'md' }}>
        <ChatInput
          onSubmit={submitMessage}
          isResponding={isResponding}
          suggestedResponses={messages.length > 0 ? suggestedResponses : PRESET_INITIAL_RESPONSES}
          currentQuestionFieldName={currentQuestionFieldName}
          placeholderText={placeholderText}
          onboardingComplete={onboardingComplete}
        />
      </Box>
    </Stack>
  );
};
