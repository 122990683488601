import { FC, useState } from 'react';

import { Box, Center, Divider, Grid, Stack } from '@mantine/core';

import { usePointsOfInterest } from '../api/getPointsOfInterest';
import { PointOfInterest } from '../types';

import { LocationOverridesList } from './LocationOverridesList';
import { PointOfInterestMap } from './PointOfInterestMap';
import { PointOfInterestModal } from './PointOfInterestModal';
import { PointOfInterestTable } from './PointOfInterestTable';

interface PlacesContainerProps {
  hideLocationControls?: boolean;
}

export const PlacesContainer: FC<PlacesContainerProps> = ({ hideLocationControls = false }) => {
  const { data: pointsOfInterest } = usePointsOfInterest();

  const [currentPointOfInterestToEdit, setCurrentPointOfInterestToEdit] = useState<PointOfInterest | null>(null);
  const [isFormOpen, setIsFormOpen] = useState(false);

  const handleAddOrEditPointOfInterest = (pointOfInterest?: PointOfInterest) => {
    setIsFormOpen(true);
    if (pointOfInterest) {
      setCurrentPointOfInterestToEdit(pointOfInterest);
    }
  };

  const handleCloseForm = () => {
    setCurrentPointOfInterestToEdit(null);
    setIsFormOpen(false);
  };

  if (!pointsOfInterest) return null;

  return (
    <Stack>
      <PointOfInterestModal
        isOpen={isFormOpen}
        onClose={handleCloseForm}
        initialPointOfInterest={currentPointOfInterestToEdit}
      />
      <PointOfInterestMap existingPointsOfInterest={pointsOfInterest} />
      <Box w="100%">
        <Grid h="100%" w="100%" overflow="hidden">
          <Grid.Col span={{ base: 12, lg: hideLocationControls ? 12 : 8 }} h="100%">
            <PointOfInterestTable
              pointsOfInterest={pointsOfInterest}
              onAddOrEditPointOfInterest={handleAddOrEditPointOfInterest}
              includeAddButton={!hideLocationControls}
            />
          </Grid.Col>
          {!hideLocationControls && (
            <>
              <Grid.Col span={{ base: 12, lg: 0.5 }}>
                <Center h="100%">
                  <Divider orientation="vertical" />
                </Center>
              </Grid.Col>
              <Grid.Col span={{ base: 12, lg: 3.5 }}>
                <LocationOverridesList />
              </Grid.Col>
            </>
          )}
        </Grid>
      </Box>
    </Stack>
  );
};
