import { FC } from 'react';

import { Button, ButtonProps } from '@mantine/core';
import { IconBrandZoom } from '@tabler/icons-react';

import { useConnectZoomAccount } from '@features/auth/api/connectZoomAccount';

export const ConnectZoomButton: FC<ButtonProps> = ({
  children = 'Connect Zoom Account',
  leftSection = <IconBrandZoom size={20} />,
  ...buttonProps
}) => {
  const connectZoomMutation = useConnectZoomAccount({
    onSuccess: () => {},
  });

  const handleConnectZoom = () => {
    // This is the exact implementation from ZoomIntegrationContainer
    // eslint-disable-next-line max-len
    const zoomOAuthUrl = `https://zoom.us/oauth/authorize?response_type=code&client_id=${import.meta.env.VITE_ZOOM_CLIENT_ID}&redirect_uri=${window.location.origin}/zoom/callback&state=${window.location.pathname}`;
    window.open(zoomOAuthUrl, '_blank', 'noopener,noreferrer');
  };

  return (
    <Button
      onClick={handleConnectZoom}
      loading={connectZoomMutation.isPending}
      disabled={connectZoomMutation.isPending}
      leftSection={leftSection}
      color="blue"
      {...buttonProps}
    >
      {children}
    </Button>
  );
};
