import { Badge, Group, Stack, Text } from '@mantine/core';

import { ConnectSlackButton } from '../../../features/auth/components/ConnectSlackButton';
import { useSlackTeams } from '../api/getSlackTeams';

export function SlackIntegrationContainer() {
  const { data: slackTeams } = useSlackTeams();

  if (!slackTeams) {
    return null;
  }

  return (
    <Stack>
      {slackTeams && slackTeams.length > 0 ? (
        <>
          <Text fw={500}>Connected Slack workspaces:</Text>
          <Group gap="xs" wrap="wrap">
            {slackTeams.map(
              (team, index) =>
                team.slackTeamName && (
                  <Badge key={index} variant="default" color="blue" size="lg">
                    {team.slackTeamName}
                  </Badge>
                ),
            )}
          </Group>
        </>
      ) : (
        <Text>No Slack workspaces connected.</Text>
      )}
      <ConnectSlackButton />
    </Stack>
  );
}
