import { useEffect, useState } from 'react';

import { Center, Container, Loader, Stack, Text, Title, rem } from '@mantine/core';
import { useDebouncedCallback } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { PRIVATE_ROUTES, PUBLIC_ROUTES } from '@routes/enums';

import { useConnectZoomAccount } from '../api/connectZoomAccount';

export function ZoomOAuthPage() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [hasAttemptedConnection, setHasAttemptedConnection] = useState(false);
  const {
    mutateAsync: connectZoomAccount,
    isPending,
    error,
  } = useConnectZoomAccount({
    onSuccess: () => {
      notifications.show({
        title: 'Success',
        message: 'Zoom account connected successfully',
        color: 'green',
      });
      navigate(`${searchParams.get('state')}?tellAgentZoomConnected=true` || PRIVATE_ROUTES.INTEGRATIONS);
    },
    onError: () => {
      notifications.show({
        title: 'Error',
        message: 'Failed to connect Zoom account. Please try again.',
        color: 'red',
      });
    },
  });
  const code = searchParams.get('code');

  const handleZoomOAuth = useDebouncedCallback(() => {
    if (code && !isPending && !hasAttemptedConnection) {
      setHasAttemptedConnection(true);
      connectZoomAccount({ code, redirectUrl: `${window.location.origin}${PUBLIC_ROUTES.ZOOM_OAUTH}` });
    }
  }, 300);

  useEffect(() => {
    handleZoomOAuth();
  }, [handleZoomOAuth]);

  if (isPending) {
    return (
      <Container size="sm">
        <Center h={rem(400)}>
          <Stack gap="md" align="center">
            <Loader size="xl" />
            <Text>Connecting your Zoom account...</Text>
          </Stack>
        </Center>
      </Container>
    );
  }

  if (error) {
    return (
      <Container size="sm">
        <Stack gap="md" align="center" mt="xl">
          <Title order={2}>Oops! Something went wrong</Title>
          <Text>We couldn&apos;t connect your Zoom account. Please try again.</Text>
          <Text c="red">{error.message}</Text>
        </Stack>
      </Container>
    );
  }

  return (
    <Container size="sm">
      <Stack gap="md" align="center" mt="xl">
        <Title order={2}>Connecting Zoom Account</Title>
        <Text>Please wait while we process your Zoom authorization...</Text>
      </Stack>
    </Container>
  );
}
